import React, { useEffect } from 'react';
import { Link } from "react-router-dom";

import handsworld from '../../../img/handsworld.jpg';
import { ReactComponent as SDG1 } from '../../../img/SDG1.svg';
import { ReactComponent as SDG3 } from '../../../img/SDG3.svg';
import { ReactComponent as SDG4 } from '../../../img/SDG4.svg';
import { ReactComponent as SDG10 } from '../../../img/SDG10.svg';

import './index.css';
import { useTranslation } from "react-i18next";

export function Index() {

	const { t } = useTranslation();
	
	useEffect(() => {
		document.title = "Cobtras"
	}, [])

	return (
		<div class="clearfix">
			<div class="detail">
				<div id='headline'>
					<h1 className='headlinetext'>Cobtras</h1>
					<img src={handsworld} className='headlinepic' alt='Hände und die Welt' />
				</div>
				<h2>{t("home1")}</h2>
				<p>
					{t("home2")} <br />
					{t("home3")} <br/>
					{t("home4")}
				</p>
				<div className="goals-container">
					<div className="goal-section">
						<div className='goal-image-container'>
							<SDG1 alt="SDG1" className="goal-image" />
						</div>
						<ul className="goal-list">
							<li>{t("home5")}</li>
							<li>{t("home6")}</li>
							<li>{t("home7")}</li>
						</ul>
					</div>
					<div className="goal-section">
						<div className='goal-image-container'>
							<SDG3 alt="SDG3" className="goal-image" />
						</div>
						<ul className="goal-list">
							<li>{t("home8")}</li>
							<li>{t("home9")}</li>
						</ul>
					</div>
					<div className="goal-section">
						<div className='goal-image-container'>
							<SDG4 alt="SDG4" className="goal-image" />
						</div>
						<ul className="goal-list">
							<li>{t("home10")}</li>
							<li>{t("home11")}</li>
							<li>{t("home12")}</li>
						</ul>
					</div>
					<div className="goal-section">
						<div className='goal-image-container'>
							<SDG10 alt="SDG10" className="goal-image" />
						</div>
						<ul className="goal-list">
							<li>{t("home13")}</li>
							<li>{t("home14")}</li>
						</ul>
					</div>
				</div>
				<p>
					{t("home15")}<br /><br />
					{t("home16")}<br /><br />
					{t("home17")}<br /><br />
					<Link to="/trainer">[{t("home18")}]</Link>
				</p>
			</div>
		</div>
	);

}

export default Index;