import React, { useState, useEffect, useCallback, useMemo } from "react";
import { ToastContainer, toast } from 'react-toastify';
import { /*useDispatch, */useSelector } from 'react-redux';
import Select from 'react-select';

export function SpeakerHelperAdministration () {
	
	const [ loading, setLoading ] = useState(true);
	const [ error, setError ] = useState(null);
	const [ languages, setLanguages ] = useState([]);
	const [ selectedLanguage, setSelectedLanguage ] = useState(null);
	const [ selectedLanguageName, setSelectedLanguageName ] = useState(null);
	const [ speakerHelper, setSpeakerHelper ] = useState([]);
	const [ user, setUser ] = useState([]);
	const [ speakerHelperDataLoading, setSpeakerHelperDataLoading ] = useState(true);
	
	const username = useSelector(state => state.usermangement.username);
	const password = useSelector(state => state.usermangement.password);
	
	const getMyHeaders = useCallback(() => {
		
		let returnValue = new Headers();
		returnValue.append("Content-Type", "application/json");
		
	}, []);
	
	const getRaw = useCallback(() => {
		
		return JSON.stringify({
			"mailaddress": username,
			"password": password
		});
		 
	}, [username, password]);
	
	var myHeaders = useMemo(() => getMyHeaders(), [getMyHeaders]); 
	var raw = useMemo(() => getRaw(), [getRaw]);
	
	useEffect(() => {
		
		document.title = 'Cobtras - Speakerhelper administration';
		
		const toastid = toast.loading('Loading data ...');

		var requestOptions = {
  			method: 'POST',
  			headers: myHeaders,
  			body: raw,
  			redirect: 'follow'
		};
		
		fetch("https://cobtras.com/api/v1/adminablelanguages", requestOptions)
  			.then(response => response.text())
  			.then((result) => {
				
				let resultObj = JSON.parse(result);
				let languages = [];
				
				for(let i = 0; i < resultObj.length; i++){
					
					fetch("https://cobtras.com/api/v1/languagename/" + resultObj[i], requestOptions)
  						.then(response => response.text())
  						.then(result => {
							  
							let languageSelectObj = {
								value: resultObj[i],
								label: result
							}
					
							languages.push(languageSelectObj);
					
						  })
  						.catch(error => console.log('error', error))
					
				}
				
				setLanguages(languages);
				
				setLoading(false);
				setError(null);
				
				toast.update(toastid, { render: "Successful loaded data", type: "success", isLoading: false, autoClose: 5000 });
					  
			})
			.catch((err) => { 
				
				setError(err.message);
				setLanguages(null);
				
				toast.update(toastid, { render: "Error: " + err, type: "error", isLoading: false, autoClose: 5000 });
				
			});
		
	}, [myHeaders, raw, setError, setLoading]);
	
	function loadSpeakerHelper(toastid, successmessage, language){
		
		var requestOptions = {
  			method: 'POST',
  			headers: myHeaders,
  			body: raw,
  			redirect: 'follow'
		};
		
		fetch("https://cobtras.com/api/v1/languagespeakerhelper/" + language, requestOptions)
  			.then(response => response.text())
  			.then(result => {
							  
				setSpeakerHelper(JSON.parse(result));
								
				setSpeakerHelperDataLoading(false);
				setError(null);
				
				toast.update(toastid, { render: successmessage, type: "success", isLoading: false, autoClose: 5000 });
								
			})
		  	.catch((err) => { 
				
				setError(err.message);
				setSpeakerHelper(null);
						
				toast.update(toastid, { render: "Error: " + err, type: "error", isLoading: false, autoClose: 5000 });
					
			});
		
	}
	
	function changeLanguage(language){
		
		setSelectedLanguage(language);
		
		const toastid = toast.loading('Loading data ...');

		var requestOptions = {
  			method: 'POST',
  			headers: myHeaders,
  			body: raw,
  			redirect: 'follow'
		};
		
		fetch("https://cobtras.com/api/v1/user", requestOptions)
  			.then(response => response.text())
  			.then((result) => {
				
				let resultObj = JSON.parse(result);
				let users = [];
				
				for(let i = 0; i < resultObj.length; i++){
					
					let userSelectObj = {
						value: resultObj[i].mailAddress,
						label: resultObj[i].mailAddress + " - " + resultObj[i].firstName + " " + resultObj[i].lastName
					}
					
					users.push(userSelectObj);
					
				}
				
				setUser(users);
				
				fetch("https://cobtras.com/api/v1/languagename/" + language, requestOptions)
  					.then(response => response.text())
  					.then(result => {
							  
						setSelectedLanguageName(result);
						
						loadSpeakerHelper(toastid, "Successful loaded data", language);
						
					})
  					.catch((err) => { 
				
						setError(err.message);
						setUser(null);
						
						toast.update(toastid, { render: "Error: " + err, type: "error", isLoading: false, autoClose: 5000 });
					
					});
					  
			})
			.catch((err) => { 
				
				setError(err.message);
				setUser(null);
				
				toast.update(toastid, { render: "Error: " + err, type: "error", isLoading: false, autoClose: 5000 });
				
			});
		
	}
	
	function backToLanguageSelection(){

		setSelectedLanguage(null);
		setSpeakerHelper([]);
		setSpeakerHelperDataLoading(true);
		
	}
	
	function addUser(user){
		
		const toastid = toast.loading('Adding speaker helper ...');

		var requestOptions = {
  			method: 'POST',
  			headers: myHeaders,
  			body: raw,
  			redirect: 'follow'
		};
		
		fetch("https://cobtras.com/api/v1/setlanguagespeakerhelper/" + selectedLanguage + "/" + user, requestOptions)
  			.then(response => response.text())
  			.then(result => {
			
				if(result === "+ OK"){
					loadSpeakerHelper(toastid, "Successful added speaker helper", selectedLanguage);
				} else {
					toast.update(toastid, { render: "Error", type: "error", isLoading: false, autoClose: 5000 });
				}
			 
			})
			.catch((err) => { 
				
				setError(err.message);
				setUser(null);
				
				toast.update(toastid, { render: "Error: " + err, type: "error", isLoading: false, autoClose: 5000 });
				
			});
		
	}
	
	function removeUser(user){
		
		const toastid = toast.loading('Removing speaker helper ...');

		var requestOptions = {
  			method: 'POST',
  			headers: myHeaders,
  			body: raw,
  			redirect: 'follow'
		};
		
		fetch("https://cobtras.com/api/v1/deletelanguagespeakerhelper/" + selectedLanguage + "/" + user, requestOptions)
  			.then(response => response.text())
  			.then(result => {
			
				if(result === "+ OK"){
					loadSpeakerHelper(toastid, "Successful removed speaker helper", selectedLanguage);
				} else {
					toast.update(toastid, { render: "Error", type: "error", isLoading: false, autoClose: 5000 });
				}
			 
			})
			.catch((err) => { 
				
				setError(err.message);
				setUser(null);
				
				toast.update(toastid, { render: "Error: " + err, type: "error", isLoading: false, autoClose: 5000 });
				
			});
		
	}
	
	return (
		<div class="clearfix">
			<div class="detail">
				<h1>Speaker helper administration</h1>
				{loading && <p>Loading data...</p>}
      			{error && (
			        <p>{`There is a problem fetching the post data - ${error}`}</p>
      			)}
      			{!loading &&
      				<>
      					{selectedLanguage == null &&
	      					<>
	      						<h2>Select language for editing rights</h2>
    	  						<Select options={languages} className="select" onChange={e => changeLanguage(e.value)} />
	      					</>
      					}
      					{selectedLanguage != null &&
      						<>
      							{speakerHelperDataLoading && <p>Loading data</p>}
								{error && (
			        				<p>{`There is a problem fetching the post data - ${error}`}</p>
      							)}
      							{!speakerHelperDataLoading &&
									<>
										<p><button onClick={backToLanguageSelection}>Back</button></p>
										<h2>{selectedLanguageName}</h2>
										{speakerHelper.length === 0 &&
											<p>Currently no speaker helper for this language</p>
										}
										{speakerHelper.length !== 0 &&
											<p>
												<ul>
													{speakerHelper.map((mailAddress) =>
														<li>{mailAddress}&nbsp;&nbsp;&nbsp;<button onClick={() => removeUser(mailAddress)}>Remove</button></li>
													)}
												</ul>
											</p>
										}
										<p>Add user as speaker helper: <Select options={user} className="select" onChange={e => addUser(e.value)} /></p>
									</>
								}
							</>
						}
      				</>
      			}
				<ToastContainer />
			</div>
		</div>
	)
	
}