import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useSelector } from 'react-redux';
import { Link, useParams } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import './Vocabularies.css';

export function SelectLection () {
	
	let { langid } = useParams();
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [languageName, setLanguageName] = useState(null);
	const [newLectionName, setNewLectionName] = useState("");
	
	const [dialogOpen, setDialogOpen] = useState(false);
	const [dialogLectionName, setDialogLectionName] = useState("");
	const [dialogLectionId, setDialogLectionId] = useState("");
	const [dialogLectionNewName, setDialogLectionNewName] = useState("");
	
	const username = useSelector(state => state.usermangement.username);
	const password = useSelector(state => state.usermangement.password);
	
	const getMyHeaders = useCallback(() => {
		
		let returnValue = new Headers();
		returnValue.append("Content-Type", "application/json");
		
	}, []);
	
	const getRaw = useCallback(() => {
		
		return JSON.stringify({
			"mailaddress": username,
			"password": password
		});
		 
	}, [username, password]);
	
	var myHeaders = useMemo(() => getMyHeaders(), [getMyHeaders]); 
	var raw = useMemo(() => getRaw(), [getRaw]);
	
	const setLectionName = useCallback((lections, i) => { 
		
		if(i === lections.length){
			
			setData(lections);
			setLoading(false);
			
		} else {
			
			var requestOptions = {
  				method: 'POST',
  				headers: myHeaders,
  				body: raw,
  				redirect: 'follow'
			};

			fetch("https://cobtras.com/api/v1/lectionname/" + lections[i].id, requestOptions)
  				.then(response => response.text())
  				.then((result) => {
					
					lections[i].name = result;
					  
					setLectionName(lections, (i + 1));
			
				})
				.catch((err) => { 
					setError(err.message);
					setData(null);
				});
			
		}
		
	}, [setData, setLoading, myHeaders, raw, setError]);
	
	const getLections = useCallback((requestOptions) => {
		
		fetch("https://cobtras.com/api/v1/lections/" + langid + "/1", requestOptions)
  			.then(response => response.text())
  			.then((result) => {
				
				let resultObject = JSON.parse(result);
				let lections = [];
				
				for(let i = 0; i < resultObject.length; i++){
					
					let obj = {
						id: resultObject[i],
						name: ""
					}
					
					lections.push(obj);
					
				}
				
				setLectionName(lections, 0);
				
				setError(null);
			})
			.catch((err) => { 
				setError(err.message);
				setData(null);
			});
		
	}, [setError, setLectionName, setData, langid]);
	
	useEffect(() => {
		
		var requestOptions = {
  			method: 'POST',
  			headers: myHeaders,
  			body: raw,
  			redirect: 'follow'
		};
		
		fetch("https://cobtras.com/api/v1/languagename/" + langid, requestOptions)
			.then(response => response.text())
			.then((result) => {
				setLanguageName(result);
			})
			.catch((err) => { 
				setError(err.message);
				setData(null);
			});
			
		getLections(requestOptions);
		
	}, [myHeaders, raw, langid, getLections]);
	
	function handleNewLection(event) {
		
		event.preventDefault();
		
		if(newLectionName.length === 0){
			
			toast.error('You have to insert a name for the new lection', { autoClose: 5000 });
			
			return;
			
		}
		
		const toastid = toast.loading('Saving new lection ...');
		
		let json = JSON.stringify({
			"mailaddress": username,
			"password": password,
			"name": newLectionName,
			"lang": langid,
			"datasetType": 1
		});
		
		var requestOptions = {
  			method: 'POST',
  			headers: myHeaders,
  			body: json,
  			redirect: 'follow'
		};
		
		fetch("https://cobtras.com/api/v1/newlection", requestOptions)
			.then(response => response.text())
			.then((result) => {
				
				if(result === "+ OK"){
					
					var requestOptions = {
  						method: 'POST',
  						headers: myHeaders,
  						body: raw,
  						redirect: 'follow'
					};
					
					getLections(requestOptions);
					
					toast.update(toastid, { render: "Successful added lection", type: "success", isLoading: false, autoClose: 5000 });
					
					setNewLectionName("");
					
				} else {
					toast.update(toastid, { render: "Error: " + result, type: "error", isLoading: false, autoClose: 5000 });
				}

			})
			.catch((err) => { 
				toast.update(toastid, { render: "Error: " + err, type: "error", isLoading: false, autoClose: 5000 });
			});
		
	}
	
	useEffect(() => {
    	
    	document.title = 'Cobtras - Vocabulary management  ' + languageName;
    	
  	}, [languageName]);
	
	function renameLection(id, name){
		
		setDialogLectionId(id);
		setDialogLectionName(name);
		
		setDialogOpen(true);
		
	}
	
	function handleDialogCancel(){
		setDialogOpen(false);
	}
	
	function saveRenamedLection(){
		
		if(dialogLectionNewName.length === 0){
			
			toast.error('You have to insert a name for the lection', { autoClose: 5000 });
			
			return;
			
		}
		
		const toastid = toast.loading('Renaming lection ...');
		
		let json = JSON.stringify({
			"mailaddress": username,
			"password": password,
			"name": dialogLectionNewName,
			"lectionid": dialogLectionId
		});
		
		var requestOptions = {
  			method: 'POST',
  			headers: myHeaders,
  			body: json,
  			redirect: 'follow'
		};
		
		fetch("https://cobtras.com/api/v1/renamelection", requestOptions)
			.then(response => response.text())
			.then((result) => {
				
				if(result === "+ OK"){
					
					var requestOptions = {
  						method: 'POST',
  						headers: myHeaders,
  						body: raw,
  						redirect: 'follow'
					};
					
					getLections(requestOptions);
					
					toast.update(toastid, { render: "Successful renamed lection", type: "success", isLoading: false, autoClose: 5000 });
					
					setDialogOpen(false);
					setDialogLectionName("");
					setDialogLectionNewName("");
					
				} else {
					toast.update(toastid, { render: "Error: " + result, type: "error", isLoading: false, autoClose: 5000 });
				}

			})
			.catch((err) => { 
				toast.update(toastid, { render: "Error: " + err, type: "error", isLoading: false, autoClose: 5000 });
			});
		
	}
	
	return (
		<div class="clearfix">
			<div class="detail">
			<h1>Vocabulary Management</h1>
				{loading && <p>Loading data...</p>}
      			{error && (
			        <p>{`There is a problem fetching the post data - ${error}`}</p>
      			)}
      			{data && 
      				<>
      					<h2>Please select a lection of {languageName}</h2>
      					<ul className="listul">
      						{data.map(({ id, name }) => (
								  <li key={id}><Link to={ `/vocabularies/${langid}/${id}` }>{name}</Link> <span onClick={() => renameLection(id, name)}><FontAwesomeIcon icon="fa-solid fa-pen" /></span></li>
							))}
							<li key={"new"}>
								<form onSubmit={handleNewLection}>
									<input name="name" placeholder='new lection name' value={newLectionName}  onChange={e => setNewLectionName(e.target.value)}  />
									<input className="saveNewBtn" type="submit" value="Save new lection" />
								</form>
							</li>
      					</ul>
      				</>
      			}
				<ToastContainer />
				<Dialog open={dialogOpen} onClose={handleDialogCancel} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
					<DialogTitle id="alert-dialog-title">
						Rename lection "{dialogLectionName}"
        			</DialogTitle>
        			<DialogContent>
          				<DialogContentText id="alert-dialog-description">
          					<input id="inputname" type="text" name="newname" required placeholder='new name' value={dialogLectionNewName}  onChange={e => setDialogLectionNewName(e.target.value) } />
          				</DialogContentText>
        			</DialogContent>
					<DialogActions>
						<Button onClick={saveRenamedLection}>Save</Button>
          				<Button onClick={handleDialogCancel}>Cancel</Button>
        			</DialogActions>
				</Dialog>
      		</div>
		</div>
	);
	
}