import React, { useEffect } from 'react';
import { useTranslation } from "react-i18next";

import handsworld from '../../../img/handsworld.jpg';

export function Values() {

	const { t } = useTranslation();
	
	useEffect(() => {
		document.title = t("valuestitle")
	}, [t])
	
	return (
		<div class="clearfix">
			<div class="detail">
				<div id='headline'>
					<h1 className='headlinetext'>{t("values1")}</h1>
					<img src={handsworld} className='headlinepic' alt='Hände und die Welt' />
				</div>
				<h2>{t("values2")}</h2>
				<p>{t("values3")}</p>
				<h2>{t("values4")}</h2>
				<p>{t("values5")}</p>
				<h2>{t("values6")}</h2>
				<p>{t("values7")}</p>
				<h2>{t("values8")}</h2>
				<p>{t("values9")}</p>
				<h2>{t("values10")}</h2>
				<p>{t("values11")}</p>
				<h2>{t("values12")}</h2>
				<p>{t("values13")}</p>
				<h2>{t("values14")}</h2>
				<p>{t("values15")}</p>
				<h2>{t("values16")}</h2>
				<p>{t("values17")}</p>
				<h2>{t("values18")}</h2>
				<p>{t("values19")}</p>
				<h2>{t("values20")}</h2>
				<p>{t("values21")}</p>
			</div>
		</div>
	);
	
}
