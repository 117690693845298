import React, { useState, useEffect, useCallback, useMemo } from "react";
import { ToastContainer, toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { Link, useParams } from "react-router-dom";

import { SpokenLanguageShower } from '../../../features/viewfeatures/SpokenLanguageShower';

import './TeacherTranslations.css';

export function TeacherTranslations () {
	
	const [ loading, setLoading ] = useState(true);
	const [ error, setError ] = useState(null);
	const [ texts, setTexts ] = useState([]);
	const [ languages, setLanguages ] = useState([]);
	
	const username = useSelector(state => state.usermangement.username);
	const password = useSelector(state => state.usermangement.password);
	
	let { langid } = useParams();
	
	const getMyHeaders = useCallback(() => {
		
		let returnValue = new Headers();
		returnValue.append("Content-Type", "application/json");
		
	}, []);
	
	const getRaw = useCallback(() => {
		
		return JSON.stringify({
			"mailaddress": username,
			"password": password
		});
		 
	}, [username, password]);
	
	var myHeaders = useMemo(() => getMyHeaders(), [getMyHeaders]); 
	var raw = useMemo(() => getRaw(), [getRaw]);
	
	useEffect(() => {
		
		document.title = 'Cobtras - Teacher translation select language';
		
		const toastid = toast.loading('Loading data ...');

		var requestOptions = {
  			method: 'POST',
  			headers: myHeaders,
  			body: raw,
  			redirect: 'follow'
		};
		
		fetch("https://cobtras.com/api/v1/teachertexts", requestOptions)
  			.then(response => response.text())
  			.then((result) => {
				  
				let data = [];

				let resultObj = JSON.parse(result);

				for(let i = 0; i < resultObj.length; i++){

					fetch("https://cobtras.com/api/v1/teachertext/" + resultObj[i][0] + "/" + langid, requestOptions)
  						.then(response => response.text())
  						.then((result) => {
					
							let dataObj = {
								id: resultObj[i][0],
								eng: resultObj[i][1],
								translation: result
							}
					
							data.push(dataObj);
							
						})
						.catch((err) => { 
				
							setError(err.message);
							setLoading(false);
				
							toast.update(toastid, { render: "Error: " + err, type: "error", isLoading: false, autoClose: 5000 });
				
						});
					
				}

				setTexts(data);
				  
				fetch("https://cobtras.com/api/v1/spokenlanguages", requestOptions)
					.then(response => response.text())
					.then((result) => {
				
						setLanguages(JSON.parse(result));
				
						setLoading(false);
						setError(null);
				
						toast.update(toastid, { render: "Successful loaded data", type: "success", isLoading: false, autoClose: 5000 });
							
					})
					.catch((err) => { 
				
						setLanguages(null);
						setError(err.message);
						setLoading(false);
				
						toast.update(toastid, { render: "Error: " + err, type: "error", isLoading: false, autoClose: 5000 });
						
					});
				  
			})
			.catch((err) => { 
				
				setError(err.message);
				setLoading(false);
				
				toast.update(toastid, { render: "Error: " + err, type: "error", isLoading: false, autoClose: 5000 });
				
			});
		
		
	}, [myHeaders, raw, langid]);
	
	function enterTranslation(event){
		
		if (event.target.value !== ""){
			
			const toastid = toast.loading('Updating translation ...');
			
			let body = JSON.stringify({
				"mailaddress": username,
				"password": password,
				"value": event.target.value
			});

			var requestOptions = {
  				method: 'POST',
  				headers: myHeaders,
  				body: body,
  				redirect: 'follow'
			};
		
			fetch("https://cobtras.com/api/v1/setteachertext/" + event.target.parentNode.parentNode.id + "/" + langid, requestOptions)
				.then(response => response.text())
				.then((result) => {
					
					if(result === "+ OK"){
						toast.update(toastid, { render: "Successful updated translation", type: "success", isLoading: false, autoClose: 5000 });
					} else {
						toast.update(toastid, { render: "Error updating translation ", type: "error", isLoading: false, autoClose: 5000 });
					}
					
				})
				.catch((err) => { 
					toast.update(toastid, { render: "Error: " + err, type: "error", isLoading: false, autoClose: 5000 });
				})
			
		}		
		
	}

	return(
		<div class="clearfix">
			<div class="detail">
				<h1>Translations</h1>
				{loading && <p>Loading data...</p>}
      			{error && (
			        <p>{`There is a problem fetching the post data - ${error}`}</p>
      			)}
      			{!loading && 
      				<>
      					<p><Link to="/teachertranslations">Back</Link></p>
      					<h2>Translation to <SpokenLanguageShower value={langid} list={languages} /></h2>
      					<table className="teachertranslationtable">
      						<tbody>
      							{/*TODO*/}
      							{texts.map((item) => (
      								<tr key={item.id} id={item.id}>
      									<td>{item.id}</td>
      									<td>{item.eng}</td>
      									<td><textarea onBlur={e => enterTranslation(e) } defaultValue={item.translation} /></td>
      								</tr>	
      							))}
      						</tbody>
      					</table>
					</>
      			}
				<ToastContainer />
			</div>
		</div>
	);
	
}