import React from 'react'

export function Sign (props) {
	
	const name = props.name;
	const parser = new DOMParser();
	const decodedString = parser.parseFromString(`<!doctype html><body>${name}`, 'text/html').body.textContent;
	
	return(
		<>
			{decodedString}
		</>
	)
	
}