import { configureStore } from '@reduxjs/toolkit';
import userManagementReducer from '../features/usermanagement/usermanagementSlice';
import datasettypesSlice from '../features/datasettypes/datasettypesSlice';
import aistatesSlice from '../features/aistates/aistatesSlice';
import signlanguagesSlice from '../features/signlanguages/signlanguagesSlice'

export const store = configureStore({
  reducer: {
    usermangement: userManagementReducer,
    datasettypes: datasettypesSlice,
    aistates: aistatesSlice,
    signlanguages: signlanguagesSlice
  },
});
