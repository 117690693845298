import React, { useState, useEffect, useCallback, useMemo } from "react";
import { ToastContainer, toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";

import { SignLanguageShower } from '../../../features/viewfeatures/SignLanguageShower';
import { SpokenLanguageShower } from '../../../features/viewfeatures/SpokenLanguageShower';

export function TranslationSelectLanguage () {
	
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [ signLanguages, setSignLanguages] = useState([]);
	const [ languages, setLanguages ] = useState([]);
	const [ translatorLanguages, setTranslatorLanguages ] = useState([]);
	
	const username = useSelector(state => state.usermangement.username);
	const password = useSelector(state => state.usermangement.password);
	
	const getMyHeaders = useCallback(() => {
		
		let returnValue = new Headers();
		returnValue.append("Content-Type", "application/json");
		
	}, []);
	
	const getRaw = useCallback(() => {
		
		return JSON.stringify({
			"mailaddress": username,
			"password": password
		});
		 
	}, [username, password]);
	
	var myHeaders = useMemo(() => getMyHeaders(), [getMyHeaders]); 
	var raw = useMemo(() => getRaw(), [getRaw]);
	
	useEffect(() => {
		
		document.title = 'Cobtras - Translation select language';

		const toastid = toast.loading('Loading data ...');

		var requestOptions = {
  			method: 'POST',
  			headers: myHeaders,
  			body: raw,
  			redirect: 'follow'
		};

		fetch("https://cobtras.com/api/v1/translator/" + username, requestOptions)
  			.then(response => response.text())
  			.then((result) => {
				
				setTranslatorLanguages(JSON.parse(result));
				
				fetch("https://cobtras.com/api/v1/signlanguages", requestOptions)
  					.then(response => response.text())
  					.then((result) => {
				
					let resultObj = JSON.parse(result);
					let signLanguages = [];
					
					for(let i = 0; i < resultObj.length; i++){
						
						let signLanguageSelectObj = {
							value: resultObj[i].id,
							label: resultObj[i].name
						}
						
						signLanguages.push(signLanguageSelectObj);
					
					}
					
					setSignLanguages(resultObj);
					
					fetch("https://cobtras.com/api/v1/spokenlanguages", requestOptions)
  						.then(response => response.text())
  						.then((result) => {
				
							setLanguages(JSON.parse(result));
				
							setLoading(false);
							setError(null);
				
							toast.update(toastid, { render: "Successful loaded data", type: "success", isLoading: false, autoClose: 5000 });
							
						})
						.catch((err) => { 
				
							setLanguages(null);
							setError(err.message);
							setLoading(false);
				
							toast.update(toastid, { render: "Error: " + err, type: "error", isLoading: false, autoClose: 5000 });
				
						});
					
				})
				.catch((err) => { 
				
					setError(err.message);
					setSignLanguages(null);
					setLoading(false);
				
					toast.update(toastid, { render: "Error: " + err, type: "error", isLoading: false, autoClose: 5000 });
				
				});
					  
			})
			.catch((err) => { 
				
				setTranslatorLanguages(null);
				setError(err.message);
				setLoading(false);
				
				toast.update(toastid, { render: "Error: " + err, type: "error", isLoading: false, autoClose: 5000 });
				
			});
		
	}, [setError, myHeaders, raw, setLoading, username]);
	
	return (
		<div class="clearfix">
			<div class="detail">
				<h1>Translations</h1>
				{loading && <p>Loading data...</p>}
      			{error && (
			        <p>{`There is a problem fetching the post data - ${error}`}</p>
      			)}
      			{!loading && 
      				<>
      					<h2>Please select a sign language</h2>
      					<ul className="listul">
							{translatorLanguages.map((languageCombination) => (
								<li>
									<Link to={`/translations/${languageCombination[0]}/${languageCombination[1]}`}>
										<SignLanguageShower value={languageCombination[0]} list={signLanguages} /> - <SpokenLanguageShower value={languageCombination[1]} list={languages} />
									</Link>
								</li>
							))}
      					</ul>
      				</>
      			}
				<ToastContainer />
			</div>
		</div>
	);
	
}