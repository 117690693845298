import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';

const ValidateRegistration = () => {
	
	const [searchParams, ] = useSearchParams();
	const [activationSuccessful, setActivationSuccessful] = useState(false);
	const [doOnStart, setDoOnStart] = useState(true);
	
	let mailAddress = searchParams.get("mailaddress");
	let authCode = searchParams.get("authcode");
	
	useEffect(() => {
		
		if(doOnStart){
		
			setDoOnStart(false);
			
			var headers = new Headers();
			headers.append("Content-Type", "application/json");
	
			var registrationData = {
				mailAddress: mailAddress,
				authCode: authCode
			}
			
			var requestOptions = {
  				method: 'POST',
  				headers: headers,
  				body: JSON.stringify(registrationData),
  				redirect: 'follow'
			};
		
			const toastid = toast.loading('Registrierung aktivieren ...');
		
			fetch("https://cobtras.com/api/v1/activateregistration", requestOptions)
  				.then(response => response.text())
  				.then(result => handleregistrationresult(toastid, result))
  				.catch(error => handleerrorresult(toastid, error));
		
		}
		
	}, [doOnStart, setDoOnStart, authCode, mailAddress]);
  		
	function handleregistrationresult(toastid, result){
		
		if(result === "+ OK"){
			
			setActivationSuccessful(true);
			
			toast.update(toastid, { render: "Aktivierung erfolgreich", type: "success", isLoading: false, autoClose: 5000 });
			
		} else {
			toast.update(toastid, { render: "Aktivierung fehlgeschlagen", type: "error", isLoading: false, autoClose: 5000 });
		}
			
	}  		
	
	function handleerrorresult(toastid, error){
  		toast.update(toastid, { render: "Error: " + error, type: "error", isLoading: false, autoClose: 5000 });
	}

	return (
		<>
			<h1>Registrierung</h1>
			{activationSuccessful &&
				<p>Sie wurden erfolgreich freigeschaltet und k&ouml;nnen sich nun <Link to="/login">einloggen</Link>.</p>
			}
			<ToastContainer />
		</>
	);
	
}

export default ValidateRegistration;