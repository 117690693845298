import React from 'react'
import { useSelector } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export function AiState (props) {
	
	const aiStates = useSelector(state => state.aistates.content);
	console.log(aiStates.find(x => x.id === 1).name);
			
	return (
		<>
			{props.state === 1 &&
				<FontAwesomeIcon icon="fa-regular fa-clock" title={aiStates.find(x => x.id === 1).name} />
        	}
			{props.state === 2 &&
				<FontAwesomeIcon icon="fa-solid fa-brain" title={aiStates.find(x => x.id === 2).name} />
        	}
			{props.state === 3 &&
				<FontAwesomeIcon icon="fa-solid fa-check" title={aiStates.find(x => x.id === 3).name} />
        	}
			{props.state === 4 &&
				<FontAwesomeIcon icon="fa-solid fa-triangle-exclamation" title={aiStates.find(x => x.id === 4).name} />
			}
		</>
	);
	
}