import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useSelector } from 'react-redux';
import { Link, useParams } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { Sign } from "../../../features/viewfeatures/Sign"; 

import './Vocabularies.css';

export function Lection () {
	
	let { langid, lectionid } = useParams();
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [lections, setLections] = useState([]);
	const [languageName, setLanguageName] = useState(null);
	const [lectionName, setViewLectionName] = useState(null);
	
	const [dialogOpen, setDialogOpen] = useState(false);
	const [dialogSignName, setDialogSignName] = useState("");
	const [dialogSignId, setDialogSignId] = useState("");
	
	const [newSignName, setNewSignName] = useState("");
	
	const username = useSelector(state => state.usermangement.username);
	const password = useSelector(state => state.usermangement.password);
	
	const getMyHeaders = useCallback(() => {
		
		let returnValue = new Headers();
		returnValue.append("Content-Type", "application/json");
		
	}, []);
	
	const getRaw = useCallback(() => {
		
		return JSON.stringify({
			"mailaddress": username,
			"password": password
		});
		 
	}, [username, password]);
	
	var myHeaders = useMemo(() => getMyHeaders(), [getMyHeaders]); 
	var raw = useMemo(() => getRaw(), [getRaw]);
	
	const setSignNames = useCallback((signs, i) => { 
		
		if(i === signs.length){
			
			setData(signs);
			setLoading(false);
			
		} else {
			
			var requestOptions = {
  				method: 'POST',
  				headers: myHeaders,
  				body: raw,
  				redirect: 'follow'
			};

			fetch("https://cobtras.com/api/v1/getwordtosignid/" + signs[i].id, requestOptions)
  				.then(response => response.text())
  				.then((result) => {
					
					signs[i].name = result;
					  
					setSignNames(signs, (i + 1));
			
				})
				.catch((err) => { 
					setError(err.message);
					setData(null);
				});
			
		}
		
	}, [setData, setLoading, myHeaders, raw, setError]);
	
	const getSigns = useCallback((requestOptions) => {
		
		fetch("https://cobtras.com/api/v1/signs/" + lectionid, requestOptions)
  			.then(response => response.text())
  			.then((result) => {
				
				let resultObject = JSON.parse(result);
				let signs = [];
				
				for(let i = 0; i < resultObject.length; i++){
					
					let sign = {
						id: resultObject[i],
						name: ""
					}
					
					signs.push(sign);
					
				}
				
				setSignNames(signs, 0);
				
				setError(null);
			})
			.catch((err) => { 
				setError(err.message);
				setData(null);
			});
		
	}, [setError, setSignNames, setData, lectionid]);
	
	const setLectionName = useCallback((lections, i) => { 
		
		if(i === lections.length){
			
			setLections(lections);
			setLoading(false);
			
		} else {
			
			var requestOptions = {
  				method: 'POST',
  				headers: myHeaders,
  				body: raw,
  				redirect: 'follow'
			};

			fetch("https://cobtras.com/api/v1/lectionname/" + lections[i].id, requestOptions)
  				.then(response => response.text())
  				.then((result) => {
					
					lections[i].name = result;
					  
					setLectionName(lections, (i + 1));
			
				})
				.catch((err) => { 
					setError(err.message);
					setData(null);
				});
			
		}
		
	}, [setData, setLoading, myHeaders, raw, setError]);
	
	const getLections = useCallback((requestOptions) => {
		
		fetch("https://cobtras.com/api/v1/lections/" + langid + "/1", requestOptions)
  			.then(response => response.text())
  			.then((result) => {
				
				let resultObject = JSON.parse(result);
				let lections = [];
				
				for(let i = 0; i < resultObject.length; i++){
					
					let obj = {
						id: resultObject[i],
						name: ""
					}
					
					lections.push(obj);
					
				}
				
				setLectionName(lections, 0);
				
				setError(null);
			})
			.catch((err) => { 
				setError(err.message);
				setData(null);
			});
		
	}, [setError, setLectionName, setData, langid]);
	
	useEffect(() => {
		
		var requestOptions = {
  			method: 'POST',
  			headers: myHeaders,
  			body: raw,
  			redirect: 'follow'
		};
		
		fetch("https://cobtras.com/api/v1/languagename/" + langid, requestOptions)
			.then(response => response.text())
			.then((result) => {
				setLanguageName(result);
			})
			.catch((err) => { 
				setError(err.message);
				setData(null);
			})
		
		fetch("https://cobtras.com/api/v1/lectionname/" + lectionid, requestOptions)
			.then(response => response.text())
			.then((result) => {
				setViewLectionName(result);
			})
			.catch((err) => { 
				setError(err.message);
				setData(null);
			});
			
		getSigns(requestOptions);
		getLections(requestOptions);
		
	}, [myHeaders, raw, langid, lectionid, getSigns, getLections]);
	
	useEffect(() => {
    	
    	document.title = 'Cobtras - Vocabulary management  ' + languageName + " - " + lectionName;
    	
  	}, [languageName, lectionName]);
	
	function moveSign(id, name){
		
		setDialogSignName(name);
		setDialogSignId(id);
		
		setDialogOpen(true);
		
	}
	
	function handleDialogCancel(){
		setDialogOpen(false);
	}
	
	function moveSignTo(lectionId){
		
		const toastid = toast.loading('Moving sign ...');

		var requestOptions = {
  			method: 'POST',
  			headers: myHeaders,
  			body: raw,
  			redirect: 'follow'
		};
		
		fetch("https://cobtras.com/api/v1/movesign/" + dialogSignId + "/" + lectionId, requestOptions)
			.then(response => response.text())
			.then((result) => {
				
				if(result === "+ OK"){
					
					getSigns(requestOptions);
					
					toast.update(toastid, { render: "Successful moved sign", type: "success", isLoading: false, autoClose: 5000 });
					
				}
				
			})
			.catch((err) => { 
				toast.update(toastid, { render: "Error: " + err, type: "error", isLoading: false, autoClose: 5000 });
			})
		
		setDialogOpen(false);
		
	}
	
	function handleNewSign(event){
		
		event.preventDefault();
		
		if(newSignName.length === 0){
			
			toast.error('You have to insert a value for the new sign', { autoClose: 5000 });
			
			return;
			
		}
		
		const toastid = toast.loading('Saving new sign ...');
		
		let json = JSON.stringify({
			"mailaddress": username,
			"password": password,
			"sign": newSignName,
			"lection": lectionid
		});
		
		var requestOptions = {
  			method: 'POST',
  			headers: myHeaders,
  			body: json,
  			redirect: 'follow'
		};
		
		fetch("https://cobtras.com/api/v1/addsign", requestOptions)
			.then(response => response.text())
			.then((result) => {
				
				if(result === "+ OK"){
					
					var requestOptions = {
  						method: 'POST',
  						headers: myHeaders,
  						body: raw,
  						redirect: 'follow'
					};
					
					getSigns(requestOptions);
					
					toast.update(toastid, { render: "Successful added sign", type: "success", isLoading: false, autoClose: 5000 });
					
					setNewSignName("");
					
				} else {
					toast.update(toastid, { render: "Error: " + result, type: "error", isLoading: false, autoClose: 5000 });
				}

			})
			.catch((err) => { 
				toast.update(toastid, { render: "Error: " + err, type: "error", isLoading: false, autoClose: 5000 });
			});
		
	}
	
	return (
		<div class="clearfix">
			<div class="detail">
				<h1>Vocabulary Management</h1>
				{loading && <p>Loading data...</p>}
      			{error && (
			        <p>{`There is a problem fetching the post data - ${error}`}</p>
      			)}
      			{data && 
      				<>
      					<h2>{languageName} - {lectionName}</h2>
      					<p><Link to={ `/vocabularies/${langid}` }>Back</Link></p>
						<ul className="listul">
							{data.map(({ id, name }) => (
								<li key={id}>
									<Sign name={name} /> <span onClick={() => moveSign(id, name)}>&nbsp;<FontAwesomeIcon icon="fa-solid fa-turn-up" rotation={90} /></span>
								</li>
							))}
							<li key={"new"}>
								<form onSubmit={handleNewSign}>
									<input name="name" placeholder='new sign name' value={newSignName}  onChange={e => setNewSignName(e.target.value)}  />
									<input className="saveNewBtn" type="submit" value="Save new sign" />
								</form>
							</li>
						</ul>
      				</>
      			}
				<ToastContainer />
				<Dialog open={dialogOpen} onClose={handleDialogCancel} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
					<DialogTitle id="alert-dialog-title">
          				Move Sign "{dialogSignName}" to
        			</DialogTitle>
        			<DialogContent>
          				<DialogContentText id="alert-dialog-description">
            				<ul>
            					{lections.map(({ id, name }) => (
									<li><span onClick={() => moveSignTo(id)}>{name}</span></li>
								))}
            				</ul>
          				</DialogContentText>
        			</DialogContent>
					<DialogActions>
          				<Button onClick={handleDialogCancel}>Cancel</Button>
        			</DialogActions>
				</Dialog>
			</div>
		</div>
	);
	
}