import React, {useEffect} from 'react';
import { useTranslation } from "react-i18next";
import meeting from '../../../img/meeting.jpg';

export function Trainer () {
	
	const { t } = useTranslation();
	
	useEffect(() => {
    	document.title = t("trainertitle")
  	}, [t])
	
	return (
		<div className="clearfix">
			<div className="detail">
				<div id='headline'>
					<h1 className='headlinetext'>{t("trainer1")}</h1>
					<img src={meeting} className='headlinepic' alt='Meeting' />
				</div>
				<p>{t("trainer2")}</p>
				<p>{t("trainer3")}</p>
				<p>{t("trainer4")}</p>
				<p>{t("trainer5")}</p>
				<p>{t("trainer6")}</p>
				<h1>{t("trainer7")}</h1>
				<video controls>
					<source src={`${process.env.PUBLIC_URL}/video.mp4`} type="video/mp4" />
					Your browser does not support the video tag.
				</video>
			</div>
		</div>
	);
  
}

export default Trainer;