import React from "react"

export const Checkbox = ({ isChecked, id, checkHandler }) => {
	return (
  		<div>
   			<input
       			type="checkbox"
       			id={id}
       			checked={isChecked}
      			onChange={checkHandler}
   			/>
   		</div>
	)
}