import React from 'react';

class RegistrationImpossible extends React.Component{
	
	constructor(props){
		super(props);
		this.state = {render: true};
	}
	
	componentDidMount(){
    	document.title = "Cobtras - Registrierung"
  	}
	
	render() {
		return (
			<div class="clearfix">
				<div class="detail">
					<h1>Registrierung</h1>
					<p>
						Leider ist es derzeit nicht m&ouml;glich sich zu registrieren. Bitte probieren Sie es zu einem sp&auml;teren Zeitpunkt erneut.
            		</p>
				</div>
			</div>
		);
  }
  
}

export default RegistrationImpossible;