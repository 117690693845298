import { useSelector } from 'react-redux';

export function DatasetType (props) {
	
	const datasetTypes = useSelector(state => state.datasettypes.content);
	
	let returnValue = props.id;
	
	try {
		returnValue = datasetTypes.find(x => x.id === props.id).name; 
	} catch(ignore){}
	
	return (
		returnValue	
	);
	
}