import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	username: "",
	password: "",
	loggedIn: false,
	kiPreparerRights: false,
	speakerHelperRights: false,
	qualtyManagerRights: false,
	cutterRights: false,
	translatorRights: false,
	adminRights: false,
	superAdminRights: false,	
};

export const usermanagementSlice = createSlice({
	
	name: 'usermanagement',
	initialState,
	reducers: {
		setUserName: (state, username) => {
			state.username = username.payload;
		},
		setPassword: (state, password) => {
			state.password = password.payload;
		},
		setLoggedIn: (state, status) => {
			state.loggedIn = status.payload;
		},
		setKiPreparerRights: (state, status) => {
			state.kiPreparerRights = status.payload;
		},
		setSpeakerHelperRights: (state, status) => {
			state.speakerHelperRights = status.payload;
		},
		setQualtyManagerRights: (state, status) => {
			state.qualtyManagerRights = status.payload;
		},
		setCutterRights: (state, status) => {
			state.cutterRights = status.payload;
		},
		setTranslatorRights: (state, status) => {
			state.translatorRights = status.payload;
		},
		setAdminRights: (state, status) => {
			state.adminRights = status.payload;
		},
		setSuperAdminRights: (state, status) => {
			state.superAdminRights = status.payload;
		},
		logout: (state) => {
			state.loggedIn = false;
			state.kiPreparerRights = false;
			state.speakerHelperRights = false;
			state.qualtyManagerRights=  false;
			state.cutterRights =  false;
			state.translatorRights = false;
			state.adminRights = false;
			state.superAdminRights = false;
		}
	},	
});

export const {setUserName, setPassword, setLoggedIn, setKiPreparerRights, setSpeakerHelperRights, setQualtyManagerRights, setCutterRights, setTranslatorRights, setAdminRights,
		setSuperAdminRights, logout} = usermanagementSlice.actions;

export default usermanagementSlice.reducer;