import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const Download = () => {
	
	const adminRights = useSelector(state => state.usermangement.adminRights);
	const superAdminRights = useSelector(state => state.usermangement.superAdminRights);

	const speakerHelperRights = useSelector(state => state.usermangement.speakerHelperRights);
	const speakerHelperRightsView = !(false && speakerHelperRights && adminRights && superAdminRights);
	
	const qualtyManagerRights = useSelector(state => state.usermangement.qualtyManagerRights);
	const qualtyManagerRightsView = !(false && qualtyManagerRights && adminRights && superAdminRights);
	
	const cutterRights = useSelector(state => state.usermangement.cutterRights);
	const cutterRightsView = !(false && cutterRights && adminRights && superAdminRights);
	
	const [datasLoaded, setDatasLoaded] = useState(false);
	const [teacherLink, setTeacherLink] = useState('');
	const [teachMovieRecorderLink, seTeachMovieRecordertLink] = useState('');
	const [qMClientLink, setQMClientLink] = useState('');
	const [qMClientLinkLinux, setQMClientLinkLinux] = useState('');
	const [cutterHelperLink, setCutterHelperLink] = useState('');
	const [cutterHelperLinkLinux, setCutterHelperLinkLinux] = useState('');
	
	useEffect(() => {
		
		document.title = 'Cobtras - Downloads';
		
		function processFetchResult(response){
		
			var el = document.createElement( 'html' );
			el.innerHTML = response;
		
			let elements = el.getElementsByTagName("ul")[0].children;
		
			processCobtrasTeacher(elements);
		
			if(speakerHelperRightsView){
				processCobtrasTeacherMovieRecorder(elements);
			}
		
			if(qualtyManagerRightsView){
				processCobtrasQMClient(elements);
			}
		
			if(cutterRightsView){
				processCobtrasCutterHelper(elements);
			}
		
			setDatasLoaded(true);
		
		}
	
		function processCobtrasTeacher(elements){
		
			let startString = "Cobtras_"
		
			let version = processSoftwareVersionExtraction(elements, startString);

			setTeacherLink("https://cobtras.com/software/Cobtras_" + version + ".msi");
		
		}
	
		function processCobtrasTeacherMovieRecorder(elements){
		
			let startString = "Cobtras-Teachmovie-Recorder_"
		
			let version = processSoftwareVersionExtraction(elements, startString);

			seTeachMovieRecordertLink("https://cobtras.com/software/Cobtras-Teachmovie-Recorder_" + version + ".msi");
		
		}
	
		function processCobtrasQMClient(elements){
		
			let startString = "Cobtras-QM-Client_"
		
			let version = processSoftwareVersionExtraction(elements, startString);

			setQMClientLink("https://cobtras.com/software/Cobtras-QM-Client_" + version + ".msi");
			setQMClientLinkLinux("https://cobtras.com/software/Cobtras-QM-Client_" + version + ".AppImage");
		
		}
	
		function processCobtrasCutterHelper(elements){
		
			let startString = "Cobtras-Cutter-Helper_"
		
			let version = processSoftwareVersionExtraction(elements, startString);

			setCutterHelperLink("https://cobtras.com/software/Cobtras-Cutter-Helper_" + version + ".msi");
			setCutterHelperLinkLinux("https://cobtras.com/software/Cobtras-Cutter-Helper_" + version + ".AppImage");
		
		}
	
		function processSoftwareVersionExtraction(elements, startString){
		
			let version = []
		
			for(var i = 0; i < elements.length; i++){
			
				if(elements[i].childNodes[0].innerText.startsWith(startString) && elements[i].childNodes[0].innerText.endsWith(".msi")){
				
					let versionStart = elements[i].childNodes[0].innerText.substring(startString.length);
				
					version.push(versionStart.substring(0, (versionStart.length - 4)));
				
				}
			
			}
		
			version = version.map( a => a.split('.').map( n => +n+100000 ).join('.') ).sort()
         		.map( a => a.split('.').map( n => +n-100000 ).join('.') );
		
			return(version[version.length - 1]);
		
		}
	
		if(!datasLoaded){
			
			fetch('https://cobtras.com/software/', {redirect: 'follow'})
  				.then(response => response.text())
  				.then(result => processFetchResult(result))
			
		}
		
	}, [datasLoaded, setDatasLoaded, setTeacherLink, seTeachMovieRecordertLink, setQMClientLink, setQMClientLinkLinux, setCutterHelperLink, setCutterHelperLinkLinux, speakerHelperRightsView, cutterRightsView, qualtyManagerRightsView]);
	
	return(
		<>
			<h1>Downloads</h1>
			<h2>Cobtras Teacher</h2>
			{datasLoaded &&
				<p><a href={teacherLink} target="_blank" rel="noopener noreferrer">Download Cobtras Teacher - Windows</a></p>
			}
			{speakerHelperRightsView &&
				<>
					<h2>Cobtras TeachMovieRecorder</h2>
					{datasLoaded &&
						<p><a href={teachMovieRecorderLink} target="_blank" rel="noopener noreferrer">Download Cobtras TeachMovieRecorder - Windows</a></p>
					}
				</>
			}
			{qualtyManagerRightsView &&
				<>
					<h2>Cobtras QM Client</h2>
					{datasLoaded &&
						<p>
							<a href={qMClientLink} target="_blank" rel="noopener noreferrer">Download Cobtras QM CLient - Windows</a><br />
							<a href={qMClientLinkLinux} target="_blank" rel="noopener noreferrer">Download Cobtras QM CLient - Linux</a>
						</p>
					}
				</>
			}
			{cutterRightsView &&
				<>
					<h2>Cobtras Cutter Helper</h2>
					{datasLoaded &&
						<p>
							<a href={cutterHelperLink} target="_blank" rel="noopener noreferrer">Download Cobtras Cutter Helper - Windows</a><br />
							<a href={cutterHelperLinkLinux} target="_blank" rel="noopener noreferrer">Download Cobtras Cutter Helper - Linux</a>
						</p>
					}
				</>
			}
		</>
	);
	
}

export default Download;