import React, { useState, useEffect, useCallback, useMemo } from "react";
import { ToastContainer, toast } from 'react-toastify';
import { /*useDispatch, */useSelector } from 'react-redux';
import Select from 'react-select';

import "./SpeakerAdministration.css";
import { SpeakerType } from "./SpeakerType";
import { Checkbox } from "../../../viewobjects/Checkbox";

export function SpeakerAdministration () {
	
	const [ loading, setLoading ] = useState(true);
	const [ error, setError ] = useState(null);
	const [ languages, setLanguages ] = useState([]);
	const [ selectedLanguage, setSelectedLanguage ] = useState(null);
	const [ selectedLanguageName, setSelectedLanguageName ] = useState(null);
	const [ speaker, setSpeaker ] = useState([]);
	const [ speakerDataLoading, setSpeakerDataLoading ] = useState(true);
	
	const [ newSpeaker, setNewSpeaker ] = useState({
		name: "",
		type: 0,
		signlanguage: "",
		useForRecognition: false,
		useForControlSigns1: false,
		useForControlSigns2: false
	});
	
	const options = [
		{ value: '1', label: 'Training' },
  		{ value: '2', label: 'Test & Validation' }
	]
	
	const username = useSelector(state => state.usermangement.username);
	const password = useSelector(state => state.usermangement.password);
	
	const getMyHeaders = useCallback(() => {
		
		let returnValue = new Headers();
		returnValue.append("Content-Type", "application/json");
		
	}, []);
	
	const getRaw = useCallback(() => {
		
		return JSON.stringify({
			"mailaddress": username,
			"password": password
		});
		 
	}, [username, password]);
	
	var myHeaders = useMemo(() => getMyHeaders(), [getMyHeaders]); 
	var raw = useMemo(() => getRaw(), [getRaw]);
	
	useEffect(() => {
		
		document.title = 'Cobtras - Speaker administration';
		
		const toastid = toast.loading('Loading data ...');

		var requestOptions = {
  			method: 'POST',
  			headers: myHeaders,
  			body: raw,
  			redirect: 'follow'
		};
		
		fetch("https://cobtras.com/api/v1/adminablelanguages", requestOptions)
  			.then(response => response.text())
  			.then((result) => {
				
				let resultObj = JSON.parse(result);
				let languages = [];
				
				for(let i = 0; i < resultObj.length; i++){
					
					fetch("https://cobtras.com/api/v1/languagename/" + resultObj[i], requestOptions)
  						.then(response => response.text())
  						.then(result => {
							  
							let languageSelectObj = {
								value: resultObj[i],
								label: result
							}
					
							languages.push(languageSelectObj);
					
						  })
  						.catch(error => console.log('error', error))
					
				}
				
				setLanguages(languages);
				
				setLoading(false);
				setError(null);
				
				toast.update(toastid, { render: "Successful loaded data", type: "success", isLoading: false, autoClose: 5000 });
					  
			})
			.catch((err) => { 
				
				setError(err.message);
				setLanguages(null);
				
				toast.update(toastid, { render: "Error: " + err, type: "error", isLoading: false, autoClose: 5000 });
				
			});
		
	}, [myHeaders, raw, setError, setLoading]);
	
	function loadSpeaker(toastid, successmessage, language){
		
		var requestOptions = {
  			method: 'POST',
  			headers: myHeaders,
  			body: raw,
  			redirect: 'follow'
		};
		
		fetch("https://cobtras.com/api/v1/speaker/" + language, requestOptions)
  			.then(response => response.text())
  			.then(result => {
							  
				setSpeaker(JSON.parse(result));
				
				setSpeakerDataLoading(false);
				setError(null);
				
				toast.update(toastid, { render: successmessage, type: "success", isLoading: false, autoClose: 5000 });
								
			})
		  	.catch((err) => { 
				
				setError(err.message);
						
				toast.update(toastid, { render: "Error: " + err, type: "error", isLoading: false, autoClose: 5000 });
					
			});
		
	}
	
	function changeLanguage(language){
		
		setSelectedLanguage(language);
		
		const toastid = toast.loading('Loading data ...');

		var requestOptions = {
  			method: 'POST',
  			headers: myHeaders,
  			body: raw,
  			redirect: 'follow'
		};
		
		fetch("https://cobtras.com/api/v1/languagename/" + language, requestOptions)
			.then(response => response.text())
  			.then(result => {
							  
				setSelectedLanguageName(result);
						
				loadSpeaker(toastid, "Successful loaded data", language);
						
			})
			.catch((err) => { 
				
				setError(err.message);
				
				toast.update(toastid, { render: "Error: " + err, type: "error", isLoading: false, autoClose: 5000 });
				
			});
		
	}
	
	function backToLanguageSelection(){

		setSelectedLanguage(null);
		setSpeaker([]);
		setSpeakerDataLoading(true);
		
	}
	
	function handleNewSpeaker(event){
		
		event.preventDefault();
		
		const toastid = toast.loading('Adding speaker ...');
		
		var requestOptions = {
  			method: 'POST',
  			headers: myHeaders,
  			body: JSON.stringify({
				"mailaddress": username,
				"password": password,
				"name": newSpeaker.name,
				"type": newSpeaker.type,
				"signlanguage": selectedLanguage,
				"useForRecognition": newSpeaker.useForRecognition,
				"useForControlSigns1": newSpeaker.useForControlSigns1,
				"useForControlSigns2": newSpeaker.useForControlSigns2
			}),
  			redirect: 'follow'
		};
		
		fetch("https://cobtras.com/api/v1/addspeaker", requestOptions)
			.then(response => response.text())
  			.then(result => {
							  
				if(result === "+ OK"){
					
					setNewSpeaker({...newSpeaker, name: ""});
					setNewSpeaker({...newSpeaker, type: 0});
					setNewSpeaker({...newSpeaker, signlanguage: ""});
					setNewSpeaker({...newSpeaker, useForRecognition: false});
					setNewSpeaker({...newSpeaker, useForControlSigns1: false});
					setNewSpeaker({...newSpeaker, useForControlSigns2: false});
						
					loadSpeaker(toastid, "Successful added speaker", selectedLanguage);
					
				} else {
					toast.update(toastid, { render: "Error: " + result, type: "error", isLoading: false, autoClose: 5000 });
				}
						
			})
			.catch((err) => { 
				
				console.log(err.message);
				
				toast.update(toastid, { render: "Error: " + err, type: "error", isLoading: false, autoClose: 5000 });
				
			});
		
	}
	
	function changeUserUseFor(id, area, newValue){
		
		const toastid = toast.loading('Change usage of speaker ...');
		
		var requestOptions = {
  			method: 'POST',
  			headers: myHeaders,
  			body: JSON.stringify({
				"mailaddress": username,
				"password": password
			}),
  			redirect: 'follow'
		};
		
		let boolValue = 0;
		
		if(newValue){
			boolValue = 1;
		}
		
		fetch("https://cobtras.com/api/v1/changeusageofspeaker/" + id + "/" + area + "/" + boolValue, requestOptions)
			.then(response => response.text())
  			.then(result => {
							  
				if(result === "+ OK"){
					loadSpeaker(toastid, "Successful changed usage of speaker", selectedLanguage);
				} else {
					toast.update(toastid, { render: "Error: " + result, type: "error", isLoading: false, autoClose: 5000 });
				}
						
			})
			.catch((err) => { 
				
				console.log(err.message);
				
				toast.update(toastid, { render: "Error: " + err, type: "error", isLoading: false, autoClose: 5000 });
				
			});
		
	}
	
	return (
		<div class="clearfix">
			<div class="detail">
				<h1>Speaker administration</h1>
				{loading && <p>Loading data...</p>}
      			{error && (
			        <p>{`There is a problem fetching the post data - ${error}`}</p>
      			)}
      			{!loading &&
      				<>
      					{selectedLanguage == null &&
	      					<>
	      						<h2>Select language for editing rights</h2>
    	  						<Select options={languages} className="select" onChange={e => changeLanguage(e.value)} />
	      					</>
      					}
      					{selectedLanguage != null &&
      						<>
      							{speakerDataLoading && <p>Loading data</p>}
								{error && (
			        				<p>{`There is a problem fetching the post data - ${error}`}</p>
      							)}
      							{!speakerDataLoading &&
      								<>
      									<p><button onClick={backToLanguageSelection}>Back</button></p>
										<h2>{selectedLanguageName}</h2>
										{speaker.length === 0 &&
											<p>Currently no cutter for this language</p>
										}
										{speaker.length !== 0 &&
											<table className="speakertable">
												<thead>
													<tr>
														<td>Name</td>
														<td>Type</td>
														<td>use for recognition</td>
														<td>use for control signs 1</td>
														<td>use for control signs 2</td>
													</tr>
												</thead>
												<tbody>
													{speaker.map((speaker) => 
														<tr key={speaker.id}>
															<td>{speaker.name}</td>
															<td><SpeakerType type={speaker.type}/></td>
															<td><Checkbox id={speaker.id + "cbuseforrecognition"} isChecked={speaker.useForRecognition} checkHandler={() => changeUserUseFor(speaker.id, "recognition", !speaker.useForRecognition)} /></td>
															<td><Checkbox id={speaker.id + "cbuseforcontrolsigns1"} isChecked={speaker.useForControlSigns1} checkHandler={() => changeUserUseFor(speaker.id, "control-signs-1", !speaker.useForControlSigns1)} /></td>
															<td><Checkbox id={speaker.id + "cbuseforcontrolsigns2"} isChecked={speaker.useForControlSigns2} checkHandler={() => changeUserUseFor(speaker.id, "control-signs-2", !speaker.useForControlSigns2)} /></td>
														</tr>
													)}
												</tbody>
											</table>
										}
										<p>
											Add a new speaker: <br />
											<form onSubmit={handleNewSpeaker}>
												<input type="text" placeholder="Name" value={newSpeaker.name} onChange={e=> setNewSpeaker({ ...newSpeaker, name: e.target.value})} /><br />
												<Select options={options} onChange={e => setNewSpeaker({ ...newSpeaker, type: e.value })} />
												<input type="checkbox" value={newSpeaker.useForRecognition} onChange={e => setNewSpeaker({ ...newSpeaker, useForRecognition: e.target.value})} />use for recognition<br />
												<input type="checkbox" value={newSpeaker.useForControlSigns1}  onChange={e => setNewSpeaker({ ...newSpeaker, useForControlSigns1: e.target.value })} />use for control signs 1<br />
												<input type="checkbox" value={newSpeaker.useForControlSigns2}  onChange={e => setNewSpeaker({ ...newSpeaker, useForControlSigns2: e.target.value })} />use for control signs 2<br />
												<input type="submit" value="Add"/>
											</form>
										</p>
      								</>
      							}
							</>
						}
      				</>
      			}
				<ToastContainer />
			</div>
		</div>
	);
	
}