import React from 'react';
import faq from '../../../img/woman-questions.jpg';

class FAQ extends React.Component{
	
	constructor(props){
		super(props);
		this.state = {render: true};
	}
	
	componentDidMount(){
    	document.title = "Cobtras - FAQ"
  	}
	
	render() {
		return (
			<div class="clearfix">
				<div class="detail">
					<div id='headline'>
						<h1 className='headlinetext'>FAQ</h1>
						<img src={faq} className='headlinepic' alt='Frau die sich Fragen stellt'/>
					</div>
            		<p>
                		<strong>Ersetzt Cobtras einen Geb&auml;rdensprachdolmetscher?</strong>
            		</p>
            		<p>
            			Nein, Cobtras ist ein reiner Vokabeltrainer und kein &Uuml;bersetzungstool für Geb&auml;rdensprache.
            		</p>
            		<p>
            			<strong>Wie kann ich mit Cobtras Geb&auml;rden lernen?</strong>
            		</p>
            		<p>
            			Mit Cobtras k&ouml;nnen Sie in beide Richtungen Geb&auml;rden lernen, sowohl die Geb&auml;rde zu einem schriftsprachlichen Wort, als auch das schriftsprachliche Wort zu einer Geb&auml;rde. Beide Eingaben werden von Cobtras &uuml;berpr&uuml;ft, sodass Sie eine Kontrollinstanz beim Lernen haben.
            		</p>
            		<p>
            			<strong>Ersetzt Cobtras einen Geb&auml;rdensprachkurs?</strong>
            		</p>
            		<p>
            			Nein, Cobtras ist ein Vokabeltrainer, mit dem Sie Ihre Geb&auml;rdensprachkenntnisse vertiefen k&ouml;nnen oder die in einem Geb&auml;rdensprachkurs verwendeten Geb&auml;rden erlernen k&ouml;nnen.
            		</p>
            		<p>
            			<strong>Muss ich, um das Cobtras System nutzen zu k&ouml;nnen, bestimmte Orte aufsuchen?</strong>
            		</p>
            		<p>
            			Nein, Cobtras kann an jedem PC mit einer Cobtras 3D Webcam verwendet werden, solange eine Internetverbindung besteht. So k&ouml;nnen Sie zeit- und ortsunabh&auml;ngig lernen.
            		</p>
            		<p>
            			<strong>Brauche ich f&uuml;r Cobtras ein spezielles Computersystem?</strong>
            		</p>
            		<p>
            			Cobtras wird sowohl für Windows, Linux als auch Mac OS X zur Verf&uuml;gung gestellt und ist somit auf jedem handels&uuml;blichen PC einsetzbar. Als spezielles Utensil brauchen Sie lediglich die Cobtras 3D Webcam. Wir empfehlen aber die Nutzung eines leistungsstarken Rechners.
            		</p>
            		<p>
            			<strong>Wie wurden die Vokabeln für Cobtras ausgew&auml;hlt?</strong>
            		</p>
            		<p>
            			Die Auswahl der Vokabeln lehnt sich an das Europ&auml;ische Referenzniveau für Sprachen an.
            		</p>
            		<p>
            			<strong>Wie wird die korrekte Ausf&uuml;hrung meiner Geb&auml;rde &uuml;berpr&uuml;ft?</strong>
            		</p>
            		<p>
            			Die Richtigkeit einer Geb&auml;rde wird mittels einer Videoerkennung basierend auf einer k&uuml;nstlichen Intelligenz &uuml;berpr&uuml;ft. 
            		</p>
            		<p>
            			<strong>Wie wird die k&uuml;nstliche Intelligenz trainiert und getestet?</strong>
            		</p>
            		<p>
            			Der k&uuml;nstlichen Intelligenz werden spezielle Aufnahmen von Geb&auml;rden gezeigt. Durch diese Aufnahmen kann eine k&uuml;nstliche Intelligenz trainiert und getestet werden. Alle Aufnahmen, die zu Trainingszwecken verwendet werden sind von <i>native speakern</i>.
            		</p>
            		<p>
            			<strong>In welchen Sprachen gibt es Cobtras?</strong>
            		</p>
            		<p>
            			Derzeit arbeiten wir daran Cobtras in Deutsch für die Deutsche Geb&auml;rdensprache im westlichen Dialekt zur Verf&uuml;gung zu stellen. Das Cobtras System ist aber so konzipiert, dass quasi jede Geb&auml;rdensprache mit Cobtras gelehrt werden kann.
            		</p>
            		<p>
            			<strong>Brauche ich Vorkenntnisse um Cobtras verwenden zu k&ouml;nnen?</strong>
            		</p>
            		<p>
            			Nein, zur Verwendung von Cobtras brauchen Sie lediglich grundlegende Kenntnisse in der Verwendung eines PCs.
            		</p>
				</div>
			</div>
		);
  }
  
}

export default FAQ;