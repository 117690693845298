import { useSelector } from 'react-redux';

export function SignLanguageViewObject (props) {
	
	const signLanguages = useSelector(state => state.signlanguages.content);
	
	let returnValue = props.id;
	
	try {
		returnValue = signLanguages.find(x => x.id === props.id).name; 
	} catch(ignore){}
	
	return (
		returnValue	
	);
	
}