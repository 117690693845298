import React, { useState, useEffect, useCallback, useMemo } from "react";
import { ToastContainer, toast } from 'react-toastify';
import { /*useDispatch, */useSelector } from 'react-redux';
import Select from 'react-select';

import './UserRights.css';

export function UserRights () {
	
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [ user, setUser] = useState([]);
	const [ selectedUser, setSelectedUser] = useState(null);
	const [ userDataLoading, setUserDataLoading] = useState(true);
	
	const [ kiPreparerRights, setKiPreparerRights] = useState(false);
	const [ speakerHelperRights, setSpeakerHelperRights] = useState(false);
	const [ qualtyManagerRights, setQualtyManagerRights] = useState(false);
	const [ cutterRights, setCutterRights] = useState(false);
	const [ translatorRights, setTranslatorRights] = useState(false);
	const [ adminRights, setAdminRights] = useState(false);
	const [ superAdminRights, setSuperAdminRights] = useState(false);
	
	const username = useSelector(state => state.usermangement.username);
	const password = useSelector(state => state.usermangement.password);
	
	const getMyHeaders = useCallback(() => {
		
		let returnValue = new Headers();
		returnValue.append("Content-Type", "application/json");
		
	}, []);
	
	const getRaw = useCallback(() => {
		
		return JSON.stringify({
			"mailaddress": username,
			"password": password
		});
		 
	}, [username, password]);
	
	var myHeaders = useMemo(() => getMyHeaders(), [getMyHeaders]); 
	var raw = useMemo(() => getRaw(), [getRaw]);
	
	useEffect(() => {
		
		document.title = 'Cobtras - User rights';
		
		const toastid = toast.loading('Loading data ...');

		var requestOptions = {
  			method: 'POST',
  			headers: myHeaders,
  			body: raw,
  			redirect: 'follow'
		};
		
		fetch("https://cobtras.com/api/v1/user", requestOptions)
  			.then(response => response.text())
  			.then((result) => {
				
				let resultObj = JSON.parse(result);
				let users = [];
				
				for(let i = 0; i < resultObj.length; i++){
					
					let userSelectObj = {
						value: resultObj[i].mailAddress,
						label: resultObj[i].mailAddress + " - " + resultObj[i].firstName + " " + resultObj[i].lastName
					}
					
					users.push(userSelectObj);
					
				}
				
				setUser(users);
				
				setLoading(false);
				setError(null);
				
				toast.update(toastid, { render: "Successful loaded data", type: "success", isLoading: false, autoClose: 5000 });
					  
			})
			.catch((err) => { 
				
				setError(err.message);
				setUser(null);
				
				toast.update(toastid, { render: "Error: " + err, type: "error", isLoading: false, autoClose: 5000 });
				
			});
		
	}, [myHeaders, raw, setError, setLoading]);
	
	function loadUserData(){
		
		const toastid = toast.loading('Loading user data ...');
		
		var requestOptions = {
  			method: 'POST',
  			headers: myHeaders,
  			body: raw,
  			redirect: 'follow'
		};

		fetch("https://cobtras.com/api/v1/userrights/" + selectedUser, requestOptions)
  			.then(response => response.text())
			.then((result) => {
				
				var userRights = JSON.parse(result)

				setSpeakerHelperRights(userRights.speakerHelperRights);
	  			setCutterRights(userRights.cutterRights);
	  			setQualtyManagerRights(userRights.qualtyManagerRights);
	  			setKiPreparerRights(userRights.kiPreparerRights);
	  			
	  			setTranslatorRights(userRights.translatorRights);
	  			
	  			setAdminRights(userRights.adminRights);
	  			setSuperAdminRights(userRights.superAdminRights);
	  			
	  			setUserDataLoading(false);
	  			
				toast.update(toastid, { render: "Successful loaded user data", type: "success", isLoading: false, autoClose: 5000 });
				
  			})
			.catch((err) => { 
				
				setError(err.message);
				setUser(null);
				
				toast.update(toastid, { render: "Error: " + err, type: "error", isLoading: false, autoClose: 5000 });
				
			});
		
	}
	
	function changeUser(mailaddress){
		
		setUserDataLoading(true);
		setSelectedUser(mailaddress);
		
		loadUserData();
		
	}
	
	function getValueFor(valueName){
		
		if(valueName === "kiPreparerRights"){
			return kiPreparerRights;
		}
		
		if(valueName === "speakerHelperRights"){
			return speakerHelperRights;
		}
		
		if(valueName === "qualtyManagerRights"){
			return qualtyManagerRights;
		}
		
		if(valueName === "cutterRights"){
			return cutterRights;
		}
		
		if(valueName === "translatorRights"){
			return translatorRights;
		}
		
		if(valueName === "adminRights"){
			return adminRights;
		}
		
		if(valueName === "superAdminRights"){
			return superAdminRights;
		}
			
	}
	
	function changeUserRights(event){
		
		event.preventDefault();
		
		let shouldDo = !(getValueFor(event.target.name));
		
		if(!shouldDo){
			shouldDo = window.confirm("Are you sure");
		}
		
		const toastid = toast.loading('Updating user rights ...');
		
		let sendValue = 0;
		
		if(!(getValueFor(event.target.name))){
			sendValue = 1;
		}
		
		var requestOptions = {
  			method: 'POST',
  			headers: myHeaders,
  			body: raw,
  			redirect: 'follow'
		};

		fetch("https://cobtras.com/api/v1/setuserright/" + selectedUser + "/" + event.target.name + "/" + sendValue, requestOptions)
  			.then(response => response.text())
			.then((result) => {
				
				if(result === "+ OK"){

					toast.update(toastid, { render: "Successful updated user rights", type: "success", isLoading: false, autoClose: 5000 });
					
					loadUserData();
					
				} else {
					toast.update(toastid, { render: "Error: " + result, type: "error", isLoading: false, autoClose: 5000 });		
				}			
				
  			})
			.catch((err) => { 
				
				setError(err.message);
				setUser(null);
				
				toast.update(toastid, { render: "Error: " + err, type: "error", isLoading: false, autoClose: 5000 });
				
			});
		
		loadUserData();
		
	}
			
	return (
		<div class="clearfix">
			<div class="detail">
				<h1>User rights</h1>
				{loading && <p>Loading data...</p>}
      			{error && (
			        <p>{`There is a problem fetching the post data - ${error}`}</p>
      			)}
      			{!loading &&
      				<>
      					<h2>Select user for editing rights</h2>
      					<Select options={user} className="select" onChange={e => changeUser(e.value)} />
      					{userDataLoading && <p>Loading data...</p>}
      					{error && (
			        		<p>{`There is a problem fetching the post data - ${error}`}</p>
      					)}
      					{!userDataLoading &&
      						<>
      							<h3>{selectedUser}</h3>
      							<table className="userrightstable">
      								<tr>
      									<th>speaker helper rights</th><th>cutter rights</th><th>qualitymanager rights</th><th>translator rights</th><th>ki preparer right</th><th>admin rights</th><th>super admin rights</th>
      								</tr>
      								<tr>
      									{/*TODO*/}
      									<td><input name="speakerHelperRights" type="checkbox" checked={speakerHelperRights || false} onChange={e => changeUserRights(e)} /></td>
      									<td><input name="cutterRights" type="checkbox" checked={cutterRights || false} onChange={e => changeUserRights(e)} /></td>
      									<td><input name="qualtyManagerRights" type="checkbox" checked={qualtyManagerRights || false} onChange={e => changeUserRights(e)} /></td>
      									<td><input name="translatorRights" type="checkbox" checked={translatorRights || false} onChange={e => changeUserRights(e)} /></td>
      									<td><input name="kiPreparerRights" type="checkbox" checked={kiPreparerRights || false} onChange={e => changeUserRights(e)} /></td>
      									<td><input name="adminRights" type="checkbox" checked={adminRights || false} onChange={e => changeUserRights(e)} /></td>
      									<td><input name="superAdminRights" type="checkbox" checked={superAdminRights || false} onChange={e => changeUserRights(e)} /></td>
      								</tr>
      							</table>
      						</>
      					}
      				</>
      			}
      			<ToastContainer />
			</div>
		</div>
	);
	
}
