import React, {useEffect, useCallback, useMemo} from 'react';
import { useSelector } from 'react-redux';
import Cookies from 'universal-cookie';
import logo from './img/Logo.png';
import './App.css';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { useDispatch } from 'react-redux';

import { setLoggedIn, setKiPreparerRights, setSpeakerHelperRights, setQualtyManagerRights, setCutterRights, setTranslatorRights, setAdminRights,
		setSuperAdminRights, setUserName, setPassword} from './features/usermanagement/usermanagementSlice';

import { Navigation } from './pages/information/navigation/Navigation';
import Footer from './pages/information/footer/Footer';
import Error404 from './pages/404/Error404';
import NotLoggedIn from './pages/intern/notLoggedIn/NotLoggedIn';
import NoRights from './pages/intern/noRights/NoRights';

import Index from './pages/information/index/Index'
import UnsereVision from './pages/information/ueberCobtras/UnsereVision';
import UnsereMission from './pages/information/ueberCobtras/UnsereMission';
import News from './pages/information/news/News'
import FAQ from './pages/information/faq/FAQ';
import Kontakt from './pages/information/kontakt/Kontakt'
import Impressum from './pages/information/impressum/Impressum'
import Datenschutz from './pages/information/datenschutz/Datenschutz';

import RegistrationImpossible from './pages/registration-impossible/RegistrationImpossible';

import Login from './pages/login/Login';
import Registration from './pages/registration/Registration';
import ValidateRegistration from './pages/registration/ValidateRegistration';
import ValidateNewMailAddress from './pages/intern/usersettings/ValidateNewMailAddress';
import Intern from './pages/intern/landing/Intern';
import { UserSettings } from './pages/intern/usersettings/UserSettings';
import { UserRights } from './pages/intern/userrights/UserRights';
import { AdminLanguages } from './pages/intern/adminlanguages/AdminLanguages';
import { CutterAdministration } from './pages/intern/cutteradministration/CutterAdministration';
import { QMAdministration } from './pages/intern/qmadministration/QMAdministration';
import { SpeakerHelperAdministration } from './pages/intern/speakerhelperlanguages/SpeakerHelperAdministration';
import { SpeakerAdministration } from './pages/intern/speakeradministration/SpeakerAdministration';
import Download from './pages/intern/downloads/Downloads';
import { Aitrainings } from './pages/intern/aitrainings/Aitrainings';
import { SelectLanguage } from './pages/intern/vocabularies/SelectLanguage';
import { SelectLection } from './pages/intern/vocabularies/SelectLection';
import { Lection } from './pages/intern/vocabularies/Lection';
import { TranslatorAdministration } from './pages/intern/translatoradministration/TranslatorAdministration';
import { TranslationSelectLanguage } from './pages/intern/translations/TranslationSelectLanguage';
import { TranslationSelectLection } from './pages/intern/translations/TranslationSelectLection';
import { TranslateLection } from './pages/intern/translations/TranslateLection';
import { TeacherTranslationSelectLanguage } from './pages/intern/teachertranslations/TeacherTranslationSelectLanguage';
import { TeacherTranslations } from './pages/intern/teachertranslations/TeacherTranslations';
import "./i18n";
import Trainer from './pages/information/Trainer/Trainer';
import History from './pages/information/ueberCobtras/History';
import { Values } from './pages/information/values/Values';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import { faArrowDown, faArrowRight, faArrowUp, faBrain, faChartLine, faCheck, faPen, faTable, faTriangleExclamation, faTurnUp } from '@fortawesome/free-solid-svg-icons';

export function App() {
	
	library.add(faClock, faBrain, faCheck, faTriangleExclamation, faTable, faChartLine, faTurnUp, faPen, faArrowDown, faArrowUp, faArrowRight)
	
	const loggedIn = useSelector(state => state.usermangement.loggedIn);
	const kiPreparerRights = useSelector(state => state.usermangement.kiPreparerRights);
	const adminRights = useSelector(state => state.usermangement.adminRights);
	const translatorRights = useSelector(state => state.usermangement.translatorRights);
	const superAdminRights = useSelector(state => state.usermangement.superAdminRights);
	const kiRightsView = (kiPreparerRights || adminRights || superAdminRights);
	const adminRightsView = (adminRights || superAdminRights);
	const dispatch = useDispatch();
	
	const handleerrorresult = useCallback((toastid, error) => {
  		toast.update(toastid, { render: "Error: " + error, type: "error", isLoading: false, autoClose: 5000 });
	}, []);
	
	const handleuserrightsresult = useCallback((toastid, result, mailaddress, password) => {
    	
    	try {
      		
			var userRights = Object.create(null);
			
			userRights = JSON.parse(result)
      		
      		dispatch(setUserName(mailaddress));
      		dispatch(setPassword(password));
    		dispatch(setLoggedIn(true));
    		dispatch(setSpeakerHelperRights(userRights.speakerHelperRights));
	  		dispatch(setCutterRights(userRights.cutterRights));
	  		dispatch(setQualtyManagerRights(userRights.qualtyManagerRights));
	  		dispatch(setKiPreparerRights(userRights.kiPreparerRights));
	  		dispatch(setTranslatorRights(userRights.translatorRights));
	  		dispatch(setAdminRights(userRights.adminRights));
	  		dispatch(setSuperAdminRights(userRights.superAdminRights));
	  		
			toast.update(toastid, { render: "Login erfolgreich", type: "success", isLoading: false, autoClose: 5000 });
			
    	} catch ({error}) {
      		toast.update(toastid, { render: error, type: "error", isLoading: false, autoClose: 5000 });
    	}
		
	}, [dispatch]);
	
	const handleloginresult = useCallback((toastid, result, requestOptions, mailaddress, password) => {
		
		if(result === "+ datas correct"){
			
	  		fetch("https://cobtras.com/api/v1/userrights", requestOptions)
	  			.then(response => response.text())
  				.then(result => handleuserrightsresult(toastid, result, mailaddress, password))
  				.catch(error => handleerrorresult(toastid, error));
	  		
		} else {
			toast.update(toastid, { render: result, type: "error", isLoading: false, autoClose: 5000 });
		}
		
	}, [handleuserrightsresult, handleerrorresult]);

	const getCookies = useCallback(() => {
		return new Cookies();
	}, []);
	
	var cookies = useMemo(() => getCookies(), [getCookies]); 
	
	useEffect(() => {

		var _mtm = window._mtm = window._mtm || [];
		_mtm.push({ 'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start' });
		(function() {
			var d = document, g = d.createElement('script'), s = d.getElementsByTagName('script')[0];
			g.async = true; g.src = 'https://cobtras.com/matomo/js/container_vmdKe7du.js'; s.parentNode.insertBefore(g, s);
		})();
			
	}, []);
		
	useEffect(() => {
		
		if(typeof cookies.get('cobtras-mailaddress') !== 'undefined'){

			const toastid = toast.loading('Login ...');
			
			let mailaddress = cookies.get('cobtras-mailaddress');
			let pass = cookies.get('cobtras-password');
    	
    		var headers = new Headers();
			headers.append("Content-Type", "application/json");

			var raw = JSON.stringify({
  				"mailaddress": mailaddress,
  				"password": pass
			});

			var requestOptions = {
  				method: 'POST',
  				headers: headers,
  				body: raw,
  				redirect: 'follow'
			};

			fetch("https://cobtras.com/api/v1/checkLoginData", requestOptions)
  				.then(response => response.text())
  				.then(result => handleloginresult(toastid, result, requestOptions, mailaddress, pass))
  				.catch(error => handleerrorresult(toastid, error));
			
		}
			
	}, [cookies, handleloginresult, handleerrorresult]);
	
	return (
		<Router>
			<div className="App">
				<header className="App-header">
					<img src={logo} className="App-logo" alt="logo" />
					<Navigation />
				</header>
				<div id="contents">
					<Routes>
						<Route path='/' element={<Index />} />
						<Route path='/trainer/' element={<Trainer />} />
						<Route path='/history/' element={<History />} />
						<Route path='/vision/' element={<UnsereVision />} />
						<Route path='/mission/' element={<UnsereMission />} />
						<Route path='/values/' element={<Values />} />
						<Route path='/news/' element={<News />} />
						<Route path='/de/faq/' element={<FAQ />} />
						<Route path='/faq/' element={<FAQ />} />
						<Route path='/de/kontakt/' element={<Kontakt />} />
						<Route path='/contact/' element={<Kontakt />} />
						<Route path='/impressum/' element={<Impressum />} />
						<Route path='/datenschutz/' element={<Datenschutz />} />
						<Route path='/login/' element={<Login />} />
						<Route path='/registration/' element={<RegistrationImpossible />} />
						<Route path='/registeranyway/' element={<Registration />} />
						<Route path='/validateregistration/' element={<ValidateRegistration />} />
						<Route path='/validatenewmail/' element={<ValidateNewMailAddress />} />
						{loggedIn && 
							<>
								<Route path='/intern' element={<Intern />} />
								<Route path='/settings' element={<UserSettings />} />
								<Route path='/downloads' element={<Download />} />
								{kiRightsView &&
									<Route path='/ai' element={<Aitrainings />} />
								}
								{!kiRightsView &&
									<Route path='/ai' element={<NoRights />} />
								}
								{adminRightsView &&
									<>
										<Route path='/cutteradministration' element={<CutterAdministration />} />
										<Route path='/qmadministration' element={<QMAdministration />} />
										<Route path='/speakerhelperadministration' element={<SpeakerHelperAdministration />} />
										<Route path='/speakeradministration' element={<SpeakerAdministration />} />
									</>
								}
								{!adminRightsView &&
									<>
										<Route path='/cutteradministration' element={<NoRights />} />
										<Route path='/qmadministration' element={<NoRights />} />
										<Route path='/speakerhelperadministration' element={<NoRights />} />
										<Route path='/speakeradministration' element={<NoRights />} />
									</>
								}
								{ translatorRights &&
									<>
										<Route exact path='/translations' element={<TranslationSelectLanguage />} />
										<Route exact path='/translations/:signlanguageid/:spokenlanguageid' element={<TranslationSelectLection />} />
										<Route exact path='/translations/:signlanguageid/:spokenlanguageid/:lectionid' element={<TranslateLection />} />
										<Route exact path='/teachertranslations' element={<TeacherTranslationSelectLanguage />} />
										<Route exact path='/teachertranslations/:langid' element={<TeacherTranslations />} />
									</>
								}
								{ !translatorRights &&
									<>
										<Route path='/translations' element={<NoRights />} />
										<Route path='/teachertranslations' element={<NoRights />} />
									</>
								}
								{superAdminRights &&
									<>
										<Route exact path='/vocabularies' element={<SelectLanguage />} />
										<Route exact path='/vocabularies/:langid' element={<SelectLection />} />
										<Route exact path='/vocabularies/:langid/:lectionid' element={<Lection />} />
										<Route path='/userrights' element={<UserRights />} />
										<Route path='/adminlanguages' element={<AdminLanguages />} />
										<Route path='/translatorlanguages' element={<TranslatorAdministration />} />
										
									</>
								}
								{!superAdminRights &&
									<>
										<Route path='/vocabularies' element={<NoRights />} />
										<Route path='/userrights' element={<NoRights />} />
										<Route path='/adminlanguages' element={<NoRights />} />
										<Route path='/translatorlanguages' element={<NoRights />} />
									</>
								}
							</>
						}
						{!loggedIn &&
							<>
								<Route path='/intern' element={<NotLoggedIn />} />
								<Route path='/settings' element={<NotLoggedIn />} />
								<Route path='/downloads' element={<NotLoggedIn />} />
								<Route path='/ai' element={<NotLoggedIn />} />
								<Route path='/vocabularies' element={<NotLoggedIn />} />
								<Route path='/userrights' element={<NotLoggedIn />} />
								<Route path='/adminlanguages' element={<NotLoggedIn />} />
								<Route path='/cutteradministration' element={<NotLoggedIn />} />
								<Route path='/qmadministration' element={<NotLoggedIn />} />
								<Route path='/speakerhelperadministration' element={<NotLoggedIn />} />
								<Route path='/speakeradministration' element={<NotLoggedIn />} />
								<Route path='/translatorlanguages' element={<NotLoggedIn />} />
								<Route path='/translations' element={<NotLoggedIn />} />
								<Route path='/teachertranslations' element={<NotLoggedIn />} />
							</>
						}
						<Route path='*' element={<Error404 />}/>
					</Routes>
				</div>
				<ToastContainer />
				<Footer />
			</div>
		</Router>
	);
}