import React, { useEffect, useState, useCallback } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';

import { useDispatch } from "react-redux";

import { logout } from '../../../features/usermanagement/usermanagementSlice';

const ValidateNewMailAddress = () => {
	
	const [searchParams, ] = useSearchParams();
	const dispatch = useDispatch();
	const [mailAddressChangeSuccessful, setMailAddressChangeSuccessful] = useState(false);
	const [doOnStart, setDoOnStart] = useState(true);

	let oldMailAddress = searchParams.get("mailaddress");
	let newMailAddress = searchParams.get("newmailaddress");
	let authCode = searchParams.get("authcode");
  		
  	const handleregistrationresult = useCallback((toastid, result) => {
		
		if(result === "+ OK"){
			
			setMailAddressChangeSuccessful(true);
			
			dispatch(logout());
			
			toast.update(toastid, { render: "Successful changed mail address", type: "success", isLoading: false, autoClose: 5000 });
			
		} else {
			toast.update(toastid, { render: "Failed to change mail address", type: "error", isLoading: false, autoClose: 5000 });
		}
			
	}, [dispatch]);		

	useEffect(() => {
		
		if(doOnStart){
		
			setDoOnStart(false);
			
			var headers = new Headers();
			headers.append("Content-Type", "application/json");
			
			var mailAddressChangeData = {
				mailAddress: oldMailAddress,
				newmailAddress: newMailAddress,
				authCode: authCode
			}
			
			var requestOptions = {
  				method: 'POST',
  				headers: headers,
  				body: JSON.stringify(mailAddressChangeData),
  				redirect: 'follow'
			};
		
			const toastid = toast.loading('Changing mail address ...');
		
			fetch("https://cobtras.com/api/v1/validatemailaddresschange", requestOptions)
  				.then(response => response.text())
  				.then(result => handleregistrationresult(toastid, result))
  				.catch(error => handleerrorresult(toastid, error));
		
		}
		
	}, [doOnStart, setDoOnStart, authCode, oldMailAddress, newMailAddress, handleregistrationresult]);
	
	function handleerrorresult(toastid, error){
  		toast.update(toastid, { render: "Error: " + error, type: "error", isLoading: false, autoClose: 5000 });
	}
	
	return (
		<>
			<h1>Change of mail address</h1>
			{mailAddressChangeSuccessful &&
				<p>Your mail address was changed successful. You can <Link to="/login">log in</Link> now.</p>
			}
			<ToastContainer />
		</>
	);
	
}

export default ValidateNewMailAddress;