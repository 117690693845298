import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";

import './Vocabularies.css';

export function SelectLanguage () {
	
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	
	const username = useSelector(state => state.usermangement.username);
	const password = useSelector(state => state.usermangement.password);
	
	const getMyHeaders = useCallback(() => {
		
		let returnValue = new Headers();
		returnValue.append("Content-Type", "application/json");
		
	}, []);
	
	const getRaw = useCallback(() => {
		
		return JSON.stringify({
			"mailaddress": username,
			"password": password
		});
		 
	}, [username, password]);
	
	var myHeaders = useMemo(() => getMyHeaders(), [getMyHeaders]); 
	var raw = useMemo(() => getRaw(), [getRaw]);
	
	useEffect(() => {
		
		document.title = "Cobtras - Vocabulary Management";

		var requestOptions = {
  			method: 'POST',
  			headers: myHeaders,
  			body: raw,
  			redirect: 'follow'
		};

		fetch("https://cobtras.com/api/v1/signlanguages", requestOptions)
  			.then(response => response.text())
  			.then((result) => {
				
				let resultObject = JSON.parse(result);
				let languages = [];
				
				for(let i = 0; i < resultObject.length; i++){
					
					let obj = {
						id: resultObject[i].id,
						name: resultObject[i].name
					}
					
					languages.push(obj);
					
				}
				
				setData(languages);
				setLoading(false);
				
				setError(null);
			})
			.catch((err) => { 
				setError(err.message);
				setData(null);
			});
		
	}, [setError, setData, myHeaders, raw, setLoading]);
	
	return (
		<div class="clearfix">
			<div class="detail">
				<h1>Vocabulary Management</h1>
				{loading && <p>Loading data...</p>}
      			{error && (
			        <p>{`There is a problem fetching the post data - ${error}`}</p>
      			)}
      			{data && 
      				<>
      					<h2>Please select a sign language</h2>
      					<ul className="listul">
      						{data.map(({ id, name }) => (
								  <li key={id}><Link to={ `/vocabularies/${id}` }>{name}</Link></li>
							))}
      					</ul>
      				</>
      			}
			</div>
		</div>
	);
	
}