import React from 'react';

class Impressum extends React.Component{
	
	constructor(props){
		super(props);
		this.state = {render: true};
	}
	
	componentDidMount(){
    	document.title = "Cobtras - Impressum"
  	}
	
	render() {
		return (
			<div class="clearfix">
				<div class="detail">
					<h1>Impressum</h1>
					<h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
					<p>
						Patrick Huysmans &amp; Jonas David Stephan<br />
						Cobtras<br />
						Birkenstr. 10<br />
						52078 Aachen
					</p>
					<h2>Kontakt</h2>
					<p>
						Telefon: 0221 27848719<br />
						E-Mail: team@cobtras.com
					</p>
					<h2>EU-Streitschlichtung</h2>
					<p>
						Die Europ&auml;ische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <a href="https://ec.europa.eu/consumers/odr/" target="_blank" rel="noopener noreferrer">https://ec.europa.eu/consumers/odr/</a>.<br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.
					</p>
					<h2>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h2>
					<p>
						Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.
					</p>
					<p>Quelle: <a href="https://www.e-recht24.de" target="_blank" rel="noopener noreferrer">e-recht24.de</a></p>
				</div>
			</div>
		);
  }
  
}

export default Impressum;