import React from 'react';

class NoRights extends React.Component{
	
	constructor(props){
		super(props);
		this.state = {render: true};
	}
	
	componentDidMount(){
    	document.title = "Cobtras - no rights"
  	}
	
	render() {
		return (
			<div class="clearfix">
				<div class="detail">
					<h1>No rights</h1>
					<p>
						You don't have the rights to visit this page.
					</p>
				</div>
			</div>
		);
  }
  
}

export default NoRights;