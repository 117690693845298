import React from 'react';
import Cookies from 'universal-cookie';
import { Helmet } from 'react-helmet';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { setLoggedIn, setKiPreparerRights, setSpeakerHelperRights, setQualtyManagerRights, setCutterRights, setTranslatorRights, setAdminRights,
		setSuperAdminRights, setUserName, setPassword} from '../../features/usermanagement/usermanagementSlice';

import './Login.css'
import 'react-toastify/dist/ReactToastify.css';

const Login = () => {

	const dispatch = useDispatch();
	const navigate = useNavigate();
	
	function handleSubmit(event){
    	
    	event.preventDefault();
    	
    	const toastid = toast.loading('Login ...');
    	
		var { mailaddress, pass, stayloggedin } = document.forms[0];
    	
    	var headers = new Headers();
		headers.append("Content-Type", "application/json");

		var raw = JSON.stringify({
  			"mailaddress": mailaddress.value,
  			"password": pass.value
		});

		var requestOptions = {
  			method: 'POST',
  			headers: headers,
  			body: raw,
  			redirect: 'follow'
		};

		fetch("https://cobtras.com/api/v1/checkLoginData", requestOptions)
  			.then(response => response.text())
  			.then(result => handleloginresult(toastid, result, requestOptions, mailaddress.value, pass.value, stayloggedin.checked))
  			.catch(error => handleerrorresult(toastid, error));
    	
	}
	
	function handleloginresult(toastid, result, requestOptions, mailaddress, password, stayloggedin){
		
		if(result === "+ datas correct"){
			
	  		fetch("https://cobtras.com/api/v1/userrights", requestOptions)
	  			.then(response => response.text())
  				.then(result => handleuserrightsresult(toastid, result, mailaddress, password, stayloggedin))
  				.catch(error => handleerrorresult(toastid, error));
	  		
		} else {
			toast.update(toastid, { render: result, type: "error", isLoading: false, autoClose: 5000 });
		}
		
	}
	
	function handleuserrightsresult(toastid, result, mailaddress, password, stayloggedin) {
    	
    	try {
      		
			var userRights = Object.create(null);
			
			userRights = JSON.parse(result)
      		
      		dispatch(setUserName(mailaddress));
      		dispatch(setPassword(password));
    		dispatch(setLoggedIn(true));
    		dispatch(setSpeakerHelperRights(userRights.speakerHelperRights));
	  		dispatch(setCutterRights(userRights.cutterRights));
	  		dispatch(setQualtyManagerRights(userRights.qualtyManagerRights));
	  		dispatch(setKiPreparerRights(userRights.kiPreparerRights));
	  		dispatch(setTranslatorRights(userRights.translatorRights));
	  		dispatch(setAdminRights(userRights.adminRights));
	  		dispatch(setSuperAdminRights(userRights.superAdminRights));
	  		
	  		if(stayloggedin){

		  		const cookies = new Cookies();

		  		cookies.set('cobtras-mailaddress', mailaddress, { sameSite: true });
		  		cookies.set('cobtras-password', password, { sameSite: true });
				  
			}
    		
			toast.update(toastid, { render: "Login erfolgreich", type: "success", isLoading: false, autoClose: 5000 });
			
			navigate("/intern");
    	
    	} catch ({error}) {
      		toast.update(toastid, { render: error, type: "error", isLoading: false, autoClose: 5000 });
    	}
		
	}
	
	function handleerrorresult(toastid, error){
  		toast.update(toastid, { render: "Error: " + error, type: "error", isLoading: false, autoClose: 5000 });
	}

	return (
		<div class="clearfix">
			<Helmet>
       			<title>Cobtras - Login</title>
       		</Helmet>
			<div class="detail">
				<h1>Login</h1>
				<div className="form">
					<form onSubmit={handleSubmit}>
						<div className="input-container">
							<input type="text" name="mailaddress" required placeholder='Mailadresse' />
						</div>
						<div className="input-container">
							<input type="password" name="pass" required placeholder='Passwort' />
						</div>
						<div className="input-container">
							<input type="checkbox" name="stayloggedin"/> Eingeloggt bleiben
						</div>
						<div className="button-container">
							<input type="submit" value="Einloggen"/>
						</div>
					</form>
				</div>
				<p>
					Noch kein Account? <Link to={"/registration/"}>Jetzt registrieren</Link>
				</p>
				<ToastContainer />
			</div>
		</div>
	);

}

export default Login;