import React from 'react'

export function SignLanguageShower (props) {
	
	let writtenText = "";
	
	for(let i = 0; i < props.list.length; i++){
		
		if(props.list[i].id === props.value){
			writtenText = props.list[i].name;
		}
		
	}
	
	return (
		<>
			{writtenText}
		</>
	);
	
}