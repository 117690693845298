import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { ToastContainer, toast } from 'react-toastify';

import './Kontakt.css';
import letter from '../../../img/letter-with-pen.jpg';

const Kontakt = () => {
	
	const [message, setMessage] = useState({
		email: "",
		name: "",
		text: ""
	});

	function handleContact(event){
    	
    	event.preventDefault();
    	
    	if(!checkMailRequirements){
			return;
		}

    	if(message.name.length < 3){
			
			toast.error('Sie müssen eine Ihren Namen eingeben', { autoClose: 5000 });
			
			return;
			
		}
		
		if(message.text.length < 2){
			
			toast.error('Sie müssen einen Text eingeben', { autoClose: 5000 });
			
			return;
			
		}
		
		const toastid = toast.loading('Sende Mail ...');
    	
    	var formdata = new FormData();
		formdata.append("mail", message.email);
		formdata.append("name", message.name);
		formdata.append("text", message.text);

		var requestOptions = {
  			method: 'POST',
  			body: formdata,
  			redirect: 'follow'
		};

		fetch("https://cobtras.com/contactmailer.php", requestOptions)
  			.then(response => response.text())
  			.then(result => handleSendMailresult(toastid, result))
  			.catch(error => handleerrorresult(toastid, error));
		
	}
	
	function handleSendMailresult(toastid, result){
		
		if(result === "+ Mail send"){
			toast.update(toastid, { render: "Mail gesendet", type: "success", isLoading: false, autoClose: 5000 });
		} else {
			
			toast.update(toastid, { render: "Mail konnte nicht gesendet werden", type: "error", isLoading: false, autoClose: 5000 });
			console.log(result);
			
		}
		
	}
	
	function handleerrorresult(toastid, error){
  		toast.update(toastid, { render: "Error: " + error, type: "error", isLoading: false, autoClose: 5000 });
	}
	
	function checkMailRequirements(){
		
		if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(registration.email)) {/* eslint-disable-line */
			return true;
		} else {
			
			toast.error('Sie müssen eine gülitge Mailadresse eingeben', { autoClose: 5000 });
			
			return false;
			
		}
		
	}
	
	return (
		<div class="clearfix">
			<Helmet>
       			<title>Cobtras - Kontakt</title>
       		</Helmet>
			<div class="detail">
				<div id='headline'>
					<h1 className='headlinetext'>Kontakt</h1>
					<img src={letter} className='headlinepic' alt='Brief mit Füller'/>
				</div>
				<div className="form">
					<form onSubmit={handleContact} id="contactform">
						<div className="input-container">
							<input id="inputname" type="text" name="name" required placeholder='Name' value={message.name}  onChange={e => setMessage({ ...message, name: e.target.value })} />
							<input id="inputmail" type="mailaddress" name="pass" required placeholder='Mailadresse' value={message.email}  onChange={e => setMessage({ ...message, email: e.target.value })} />
						</div>
						<div className="input-container">
							<textarea name="text" placeholder='Nachricht' rows="10" value={message.text}  onChange={e => setMessage({ ...message, text: e.target.value })} />
						</div>
						<div className="button-container">
							<input type="submit" value="Abschicken"/>
						</div>
					</form>
				</div>
				<ToastContainer />
			</div>
		</div>
	);
  
}

export default Kontakt;