import React from 'react';
import { Link } from 'react-router-dom';

class NotLoggedIn extends React.Component{
	
	constructor(props){
		super(props);
		this.state = {render: true};
	}
	
	componentDidMount(){
    	document.title = "Cobtras - not logged in"
  	}
	
	render() {
		return (
			<div class="clearfix">
				<div class="detail">
					<h1>Not logged In</h1>
					<p>
						Unfortunately you are not logged in. Please <Link to="/login">log in</Link> to view this page.
					</p>
				</div>
			</div>
		);
  }
  
}

export default NotLoggedIn;