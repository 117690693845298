import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useSelector } from 'react-redux';
import { Link, useParams } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';

//import './Vocabularies.css';

export function TranslationSelectLection () {
	
	let { signlanguageid, spokenlanguageid } = useParams();
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [languageName, setLanguageName] = useState(null);
	
	const username = useSelector(state => state.usermangement.username);
	const password = useSelector(state => state.usermangement.password);
	
	const getMyHeaders = useCallback(() => {
		
		let returnValue = new Headers();
		returnValue.append("Content-Type", "application/json");
		
	}, []);
	
	const getRaw = useCallback(() => {
		
		return JSON.stringify({
			"mailaddress": username,
			"password": password
		});
		 
	}, [username, password]);
	
	var myHeaders = useMemo(() => getMyHeaders(), [getMyHeaders]); 
	var raw = useMemo(() => getRaw(), [getRaw]);
	
	const setLectionName = useCallback((toastid, lections, i) => { 
		
		if(i === lections.length){
			
			setData(lections);
			setLoading(false);
					
			toast.update(toastid, { render: "Successful loaded data", type: "success", isLoading: false, autoClose: 5000 });
			
		} else {
			
			var requestOptions = {
  				method: 'POST',
  				headers: myHeaders,
  				body: raw,
  				redirect: 'follow'
			};

			fetch("https://cobtras.com/api/v1/lectionname/" + lections[i].id, requestOptions)
  				.then(response => response.text())
  				.then((result) => {
					
					lections[i].name = result;
					  
					setLectionName(toastid, lections, (i + 1));
				
					setError(null);
			
				})
				.catch((err) => { 
					
					setError(err.message);
					setData(null);
					
					toast.update(toastid, { render: "Error: " + err, type: "error", isLoading: false, autoClose: 5000 });
					
				});
			
		}
		
	}, [setData, setLoading, myHeaders, raw, setError]);
	
	const getLections = useCallback((toastid, requestOptions) => {
		
		fetch("https://cobtras.com/api/v1/lections/" + signlanguageid + "/1", requestOptions)
  			.then(response => response.text())
  			.then((result) => {
				
				let resultObject = JSON.parse(result);
				let lections = [];
				
				for(let i = 0; i < resultObject.length; i++){
					
					let obj = {
						id: resultObject[i],
						name: ""
					}
					
					lections.push(obj);
					
				}
				
				fetch("https://cobtras.com/api/v1/lections/" + signlanguageid + "/2", requestOptions)
  					.then(response => response.text())
  					.then((result) => {
				
						let resultObject = JSON.parse(result);
				
						for(let i = 0; i < resultObject.length; i++){
					
							let obj = {
								id: resultObject[i],
								name: ""
							}
					
							lections.push(obj);
					
						}
				
						fetch("https://cobtras.com/api/v1/lections/" + signlanguageid + "/3", requestOptions)
  							.then(response => response.text())
  							.then((result) => {
				
								let resultObject = JSON.parse(result);
				
								for(let i = 0; i < resultObject.length; i++){
					
									let obj = {
										id: resultObject[i],
										name: ""
									}
					
									lections.push(obj);
					
								}
								
								setLectionName(toastid, lections, 0);
								
								})
								.catch((err) => { 
									
									setError(err.message);
									setData(null);
									
									toast.update(toastid, { render: "Error: " + err, type: "error", isLoading: false, autoClose: 5000 });
									
								});
						
					})
					.catch((err) => { 
						
						setError(err.message);
						setData(null);
						
						toast.update(toastid, { render: "Error: " + err, type: "error", isLoading: false, autoClose: 5000 });
						
					});
			
			})
			.catch((err) => { 
				
				setError(err.message);
				setData(null);
				
				toast.update(toastid, { render: "Error: " + err, type: "error", isLoading: false, autoClose: 5000 });
				
			});
		
	}, [setError, setLectionName, setData, signlanguageid]);
	
	useEffect(() => {
		
		const toastid = toast.loading('Loading data ...');

		var requestOptions = {
  			method: 'POST',
  			headers: myHeaders,
  			body: raw,
  			redirect: 'follow'
		};
		
		fetch("https://cobtras.com/api/v1/languagename/" + signlanguageid, requestOptions)
			.then(response => response.text())
			.then((result) => {

				setLanguageName(result);
				
				fetch("https://cobtras.com/api/v1/spokenlanguages", requestOptions)
  					.then(response => response.text())
  					.then((result) => {
		
						let spokenLanguages = JSON.parse(result);
						
						for(let i = 0; i < spokenLanguages.length; i++){
							
							if(spokenLanguages[i][0] === spokenlanguageid){
								document.title = 'Cobtras - Translation ' + languageName + " -> " + spokenLanguages[i][2];
							}
							
						}
		
					})
					.catch((err) => { 
				
						setError(err.message);
						setLoading(false);
				
						toast.update(toastid, { render: "Error: " + err, type: "error", isLoading: false, autoClose: 5000 });
				
					});
				
			})
			.catch((err) => { 
				
				setError(err.message);
				setData(null);
				
			});
			
		getLections(toastid, requestOptions);
		
	}, [myHeaders, raw, signlanguageid, getLections, languageName, spokenlanguageid]);
	
	return (
		<div class="clearfix">
			<div class="detail">
			<h1>Translations</h1>
				{loading && <p>Loading data...</p>}
      			{error && (
			        <p>{`There is a problem fetching the post data - ${error}`}</p>
      			)}
      			{data && 
      				<>
      					<h2>Please select a lection of {languageName}</h2>
      					<ul className="listul">
      						{data.map(({ id, name }) => (
								  <li key={id}><Link to={ `/translations/${signlanguageid}/${spokenlanguageid}/${id}` }>{name}</Link></li>
							))}
      					</ul>
      				</>
      			}
				<ToastContainer />
      		</div>
		</div>
	);
	
}