import React, { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import goal from '../../../img/goal.jpg';

export function UnsereMission() {
	
	const { t } = useTranslation();
	
	useEffect(() => {
    	document.title = t("missiontitle")
  	}, [t]);
	
	return (
		<div class="clearfix">
			<div class="detail">
				<div id='headline'>
					<h1 className='headlinetext'>{t("mission1")}</h1>
					<img src={goal} className='headlinepic' alt="Ziel zeigen" />
				</div>
				<p>{t("mission2")}</p>
				<p>{t("mission3")}</p>
				<p>{t("mission4")}</p>
				<p>{t("mission5")}</p>
           		<p>{t("mission6")}</p>
           		<p>{t("mission7")}</p>
           		<p>{t("mission8")}</p>
           		<p>
           			{t("mission9")}<br />
					{t("mission10")}
				</p>
			</div>
		</div>
	);
  
}

export default UnsereMission;