import React, { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import { Link, useLocation  } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { logout } from '../../../features/usermanagement/usermanagementSlice';

import './navigation.css'
import LanguageSelector from '../../../LanguageSelector';
import { useTranslation } from "react-i18next";

export function Navigation() {
	
	const loggedIn = useSelector(state => state.usermangement.loggedIn);
	const kiPreparerRights = useSelector(state => state.usermangement.kiPreparerRights);
	const adminRights = useSelector(state => state.usermangement.adminRights);
	const translatorRights = useSelector(state => state.usermangement.translatorRights);
	const superAdminRights = useSelector(state => state.usermangement.superAdminRights);
	const kiRightsView = (kiPreparerRights || adminRights || superAdminRights);
	const adminRightsView = (adminRights || superAdminRights);
	const [showNavigation, setShowNavigation] = useState(false);
	const [showNavigationAbout, setShowNavigationAbout] = useState(false);
	const [showNavigationInformation, setShowNavigationInformation] = useState(false);
	const [showNavigationAccount, setShowNavigationAccount] = useState(false);
	const [showNavigationAI, setShowNavigationAI] = useState(false);
	const [showNavigationWorkerAdministration, setShowNavigationWorkerAdministration] = useState(false);
	const [showNavigationDatasetDefintion, setShowNavigationDatasetDefintion] = useState(false);
	const [ showNavigationTranslations, setShowNavigationTranslations ] = useState(false);
	const dispatch = useDispatch();
	const location = useLocation();
	const { t } = useTranslation();

	useEffect(() => {
	    
		setShowNavigation(false);
		setShowNavigationInformation(false);
		setShowNavigationAccount(false);
		setShowNavigationAI(false);
		setShowNavigationWorkerAdministration(false);
		setShowNavigationDatasetDefintion(false);
		
		document.getElementById("hamburg").checked = false;

	}, [location]);
	
	function handleLogout(){
		
		dispatch(logout());
		
		const cookies = new Cookies();
		
		cookies.remove('cobtras-mailaddress', { sameSite: true });
		cookies.remove('cobtras-password', { sameSite: true });
		
	}
	
	return (
		<div id="nav">
			<input type="checkbox" id="hamburg" onClick={() => setShowNavigation(!showNavigation)} />
			<label htmlFor="hamburg" className="hamburg">
				<span className="line"></span>
				<span className="line"></span>
				<span className="line"></span>
			</label>
			<div className='navcontainer'>
				<ul className={`navigation ${showNavigation ? 'navvisible' : ''}`}>
					{!loggedIn &&
						<>
							<li>
								<Link to="/">{t("nav1")}</Link>
							</li>
							<li>
								<span onClick={() => setShowNavigationAbout(!showNavigationAbout)}>{t("nav2")}</span>
								{!showNavigationAbout && <span onClick={() => setShowNavigationAbout(true)}>&nbsp;<FontAwesomeIcon icon="fa-solid fa-arrow-down" /></span>}
								{showNavigationAbout &&
									<>
										<span onClick={() => setShowNavigationAbout(false)}>&nbsp;<FontAwesomeIcon icon="fa-solid fa-arrow-up" /></span>
										<ul className='dropdownnavigation'>
											<li><Link to="/vision/">{t("nav5")}</Link></li>
											<li><Link to="/mission/">{t("nav6")}</Link></li>
											<li><Link to="/values">{t("nav7")}</Link></li>
											<li><Link to="/history">{t("nav4")}</Link></li>
										</ul>
									</>
								}
							</li>
							<li>
								<Link to="/trainer">{t("nav3")}</Link>
							</li>
							<li>
								<Link to="/news">{t("nav8")}</Link>
							</li>
							<li>
								<Link to="/faq/">{t("nav9")}</Link>
							</li>
							<li>
								<Link to="/contact/">{t("nav10")}</Link>
							</li>
							<li>
								<Link to="/login" style={loggedIn ? {display: "none"} : {}}>{t("nav11")}</Link>
							</li>
							<li>
								<LanguageSelector />
							</li>
						</>
					}
					{loggedIn &&
						<>
							<li>
								<span onClick={() => setShowNavigationInformation(!showNavigationInformation)}>{t("nav12")}x</span>
								{!showNavigationInformation && <span onClick={() => setShowNavigationInformation(true)}>&nbsp;<FontAwesomeIcon icon="fa-solid fa-arrow-down" /></span>}
								{showNavigationInformation &&
									<>
										<span onClick={() => setShowNavigationInformation(false)}>&nbsp;<FontAwesomeIcon icon="fa-solid fa-arrow-up" /></span>
										<ul className='dropdownnavigation'>
											<li>
												<Link to="/">Home</Link>
											</li>
											<li>
												<Link to="/trainer">Der Vokabeltrainer</Link>
											</li>
											<li>
												<Link to="/history">Über unser Projekt</Link>
											</li>
											<li>
												<Link to="/vision/">Unsere Vision</Link>
											</li>
											<li>
												<Link to="/mission/">Unsere Mission</Link>
											</li>
											<li>
												<Link to="/news">News</Link>
											</li>
											<li>
												<Link to="/faq/">FAQ</Link>
											</li>
											<li>
												<Link to="/contact/">Kontakt</Link>
											</li>
										</ul>
									</>
								}
							</li>
							<li>
								<span onClick={() => setShowNavigationAccount(!showNavigationAccount)}>Account</span>
								{!showNavigationAccount && <span onClick={() => setShowNavigationAccount(true)}>&nbsp;<FontAwesomeIcon icon="fa-solid fa-arrow-down" /></span>}
								{showNavigationAccount &&
									<>
										<span onClick={() => setShowNavigationAccount(false)}>&nbsp;<FontAwesomeIcon icon="fa-solid fa-arrow-up" /></span>
										<ul className='dropdownnavigation'>
											<li>
												<Link to="/settings">Settings</Link>
											</li>
											<li>
												<Link to="/downloads">Downloads</Link>
											</li>
											<li>									
												<button onClick={handleLogout} style={loggedIn ? {} : {display: "none"}} class="linkbutton">
													Ausloggen
												</button>
											</li>
										</ul>
									</>
								}
							</li>
							{kiRightsView &&
								<li>
									<span onClick={() => setShowNavigationAI(!showNavigationAI)}>AI</span>
									{!showNavigationAI && <span onClick={() => setShowNavigationAI(true)}>&nbsp;<FontAwesomeIcon icon="fa-solid fa-arrow-down" /></span>}
									{showNavigationAI &&
										<>
											<span onClick={() => setShowNavigationAI(false)}>&nbsp;<FontAwesomeIcon icon="fa-solid fa-arrow-up" /></span>
											<ul className='dropdownnavigation'>
												<li>
													<Link to="/ai">Ai trainings</Link>
												</li>
											</ul>
										</>
									}
								</li>
							}
							{adminRightsView &&
								<li>
									<span onClick={() => setShowNavigationWorkerAdministration(!showNavigationWorkerAdministration)}>Worker administration</span>
									{!showNavigationWorkerAdministration && 
										<span onClick={() => setShowNavigationWorkerAdministration(true)}>&nbsp;<FontAwesomeIcon icon="fa-solid fa-arrow-down" /></span>
									}
									{showNavigationWorkerAdministration &&
										<>
											<span onClick={() => setShowNavigationWorkerAdministration(false)}>&nbsp;<FontAwesomeIcon icon="fa-solid fa-arrow-up" /></span>
											<ul className='dropdownnavigation'>
												<li>
													<Link to="/cutteradministration">Cutter administration</Link>
												</li>
												<li>
													<Link to="/qmadministration">QM administration</Link>
												</li>
												<li>
													<Link to="/speakerhelperadministration">SpeakerHelper administration</Link>
												</li>
												<li>
													<Link to="/speakeradministration">Speaker administration</Link>
												</li>
												{ superAdminRights &&
													<>
														<li>
															<Link to="/adminlanguages">Admin languages</Link>
														</li>
														<li>
															<Link to="/translatorlanguages">Translator languages</Link>
														</li>
														<li>
															<Link to="/userrights">User rights</Link>
														</li>
													</>
												}
											</ul>
										</>
									}
								</li>
							}
							{ translatorRights && 
								<li>
									{/*TODO*/}
									<span onClick={() => setShowNavigationTranslations(!showNavigationTranslations)}>Translations</span>
									{!showNavigationTranslations && 
										<span onClick={() => setShowNavigationTranslations(true)}>&nbsp;<FontAwesomeIcon icon="fa-solid fa-arrow-down" /></span>
									}
									{showNavigationTranslations &&
										<>
											<span onClick={() => setShowNavigationTranslations(false)}>&nbsp;<FontAwesomeIcon icon="fa-solid fa-arrow-up" /></span>
											<ul className='dropdownnavigation'>
												<li>
													<Link to="/translations">Translations</Link>
												</li>	
												<li>
													<Link to="/teachertranslations">Teacher Translations</Link>
												</li>							
											</ul>
										</>
									}
								</li>
							}
							{ superAdminRights &&
								<li>
									<span onClick={() => setShowNavigationDatasetDefintion(!showNavigationDatasetDefintion)}>Dataset definition</span>
									{!showNavigationDatasetDefintion && 
										<span onClick={() => setShowNavigationDatasetDefintion(true)}>&nbsp;<FontAwesomeIcon icon="fa-solid fa-arrow-down" /></span>
									}
									{showNavigationDatasetDefintion &&
										<>
											<span onClick={() => setShowNavigationDatasetDefintion(false)}>&nbsp;<FontAwesomeIcon icon="fa-solid fa-arrow-up" /></span>
											<ul className='dropdownnavigation'>
												<li>
													<Link to="/vocabularies">Manage Vocabularies</Link>
												</li>								
											</ul>
										</>
									}
								</li>
							}
						</>
					}
				</ul>
			</div>
		</div>
	);

}