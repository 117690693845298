import React from 'react';

class Intern extends React.Component{
	
	constructor(props){
		super(props);
		this.state = {render: true};
	}
	
	componentDidMount(){
    	document.title = "Cobtras"
  	}
	
	render() {
		return (
			<div class="clearfix">
				<div class="detail">
					<h1>Cobtras intern</h1>
				</div>
			</div>
		);
  }
  
}

export default Intern;