import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	loaded: false,
	content: {}
};

export const signlanguagesSlice = createSlice({
	
	name: 'datasettypes',
	initialState,
	reducers: {
		setSignLanguages: (state, content) => {
			state.loaded = true;
			state.content = content.payload;
		}
	},	
});

export const {setSignLanguages} = signlanguagesSlice.actions;

export default signlanguagesSlice.reducer;