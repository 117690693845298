import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useSelector } from 'react-redux';
import { Link, useParams } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Sign } from "../../../features/viewfeatures/Sign";
import { SpokenLanguageShower } from "../../../features/viewfeatures/SpokenLanguageShower";

export function TranslateLection () {
	
	
	let { signlanguageid, spokenlanguageid, lectionid } = useParams();
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [ languages, setLanguages ] = useState([]);
	const [languageName, setLanguageName] = useState(null);
	const [lectionName, setViewLectionName] = useState(null);
	
	const username = useSelector(state => state.usermangement.username);
	const password = useSelector(state => state.usermangement.password);
	
	const getMyHeaders = useCallback(() => {
		
		let returnValue = new Headers();
		returnValue.append("Content-Type", "application/json");
		
	}, []);
	
	const getRaw = useCallback(() => {
		
		return JSON.stringify({
			"mailaddress": username,
			"password": password
		});
		 
	}, [username, password]);
	
	var myHeaders = useMemo(() => getMyHeaders(), [getMyHeaders]); 
	var raw = useMemo(() => getRaw(), [getRaw]);
	
	const setSignTranslations = useCallback((toastid, signs, i) => {
		
		if(i === signs.length){
			
			setData(signs);
			setLoading(false);
			
			toast.update(toastid, { render: "Successful loaded data", type: "success", isLoading: false, autoClose: 5000 });
			
		} else {
			
			var requestOptions = {
  				method: 'POST',
  				headers: myHeaders,
  				body: raw,
  				redirect: 'follow'
			}
			
			fetch("https://cobtras.com/api/v1/signtranslation/" + signs[i].id + "/" + spokenlanguageid, requestOptions)
  				.then((response) => {
					
					if(response.status === 200){
						
						response.text().then(function (translation) {
							signs[i].translation = translation;
						})
					}
					  
					setSignTranslations(toastid, signs, (i + 1));
			
				})
				.catch((err) => { 
					
					setError(err.message);
					setData(null);
					
					toast.update(toastid, { render: "Error: " + err, type: "error", isLoading: false, autoClose: 5000 });
					
				});
			
		}
		
	}, [setData, setLoading, myHeaders, raw, setError, spokenlanguageid]);
	
	
	const setSignNames = useCallback((toastid, signs, i) => { 
		
		if(i === signs.length){
			setSignTranslations(toastid, signs, 0);
		} else {
			
			var requestOptions = {
  				method: 'POST',
  				headers: myHeaders,
  				body: raw,
  				redirect: 'follow'
			};

			fetch("https://cobtras.com/api/v1/getwordtosignid/" + signs[i].id, requestOptions)
  				.then(response => response.text())
  				.then((result) => {
					
					signs[i].name = result;
					  
					setSignNames(toastid, signs, (i + 1));
			
				})
				.catch((err) => { 
					
					setError(err.message);
					setData(null);
					
					toast.update(toastid, { render: "Error: " + err, type: "error", isLoading: false, autoClose: 5000 });
					
				});
			
		}
		
	}, [myHeaders, raw, setError, setSignTranslations]);
	
	const getSigns = useCallback((requestOptions, toastid) => {
		
		fetch("https://cobtras.com/api/v1/signs/" + lectionid, requestOptions)
  			.then(response => response.text())
  			.then((result) => {
				
				let resultObject = JSON.parse(result);
				let signs = [];
				
				for(let i = 0; i < resultObject.length; i++){
					
					let sign = {
						id: resultObject[i],
						name: "",
						translation: ""
					}
					
					signs.push(sign);
					
				}
				
				setSignNames(toastid, signs, 0);
				
				setError(null);
			})
			.catch((err) => { 
				
				setError(err.message);
				setData(null);
				
				toast.update(toastid, { render: "Error: " + err, type: "error", isLoading: false, autoClose: 5000 });
				
			});
		
	}, [setError, setSignNames, setData, lectionid]);
	
	const setLectionName = useCallback((lections, i) => { 
		
		if(i !== lections.length){
			
			var requestOptions = {
  				method: 'POST',
  				headers: myHeaders,
  				body: raw,
  				redirect: 'follow'
			};

			fetch("https://cobtras.com/api/v1/lectionname/" + lections[i].id, requestOptions)
  				.then(response => response.text())
  				.then((result) => {
					
					lections[i].name = result;
					  
					setLectionName(lections, (i + 1));
			
				})
				.catch((err) => { 
					setError(err.message);
					setData(null);
				});
			
		}
		
	}, [setData, myHeaders, raw, setError]);
	
	const getLections = useCallback((requestOptions, toastid) => {
		
		fetch("https://cobtras.com/api/v1/lections/" + signlanguageid + "/1", requestOptions)
  			.then(response => response.text())
  			.then((result) => {
				
				let resultObject = JSON.parse(result);
				let lections = [];
				
				for(let i = 0; i < resultObject.length; i++){
					
					let obj = {
						id: resultObject[i],
						name: ""
					}
					
					lections.push(obj);
					
				}
				
				setLectionName(lections, 0);
				
				setError(null);
			})
			.catch((err) => { 
				
				setError(err.message);
				setData(null);
				
				toast.update(toastid, { render: "Error: " + err, type: "error", isLoading: false, autoClose: 5000 });
				
			});
		
	}, [setError, setLectionName, setData, signlanguageid]);
	
	useEffect(() => {
		
		const toastid = toast.loading('Loading data ...');
		
		var requestOptions = {
  			method: 'POST',
  			headers: myHeaders,
  			body: raw,
  			redirect: 'follow'
		};
		
		fetch("https://cobtras.com/api/v1/languagename/" + signlanguageid, requestOptions)
			.then(response => response.text())
			.then((result) => {
				setLanguageName(result);
			})
			.catch((err) => { 
				
				setError(err.message);
				setData(null);
				
				toast.update(toastid, { render: "Error: " + err, type: "error", isLoading: false, autoClose: 5000 });
				
			})
			
		fetch("https://cobtras.com/api/v1/spokenlanguages", requestOptions)
  			.then(response => response.text())
  			.then((result) => {
				setLanguages(JSON.parse(result));
			})
			.catch((err) => { 
				
				setLanguages(null);
				setError(err.message);
				setLoading(false);
				
				toast.update(toastid, { render: "Error: " + err, type: "error", isLoading: false, autoClose: 5000 });
				
			});
		
		fetch("https://cobtras.com/api/v1/lectionname/" + lectionid, requestOptions)
			.then(response => response.text())
			.then((result) => {
				setViewLectionName(result);
			})
			.catch((err) => { 
				
				setError(err.message);
				setData(null);
				
				toast.update(toastid, { render: "Error: " + err, type: "error", isLoading: false, autoClose: 5000 });
				
			});
			
		getSigns(requestOptions, toastid);
		getLections(requestOptions, toastid);
		
	}, [myHeaders, raw, signlanguageid, lectionid, getSigns, getLections]);
	
	useEffect(() => {
    	
    	let spokenLanguageName = "";
		
		for(let i = 0; i < languages.length; i++){
			
			if(languages[i][0] === spokenlanguageid) {
				spokenLanguageName = languages[i][2];
			}
			
		}
    	
    	document.title = 'Cobtras - Translation ' + languageName + " - " + lectionName + " -> " + spokenLanguageName;
    	
  	}, [languageName, lectionName, languages, spokenlanguageid]);
	
	function enterTranslation(event){
		
		if (event.target.value !== ""){
			
			const toastid = toast.loading('Updating translation ...');
			

			let body = JSON.stringify({
				"mailaddress": username,
				"password": password,
				"value": event.target.value
			});

			var requestOptions = {
  				method: 'POST',
  				headers: myHeaders,
  				body: body,
  				redirect: 'follow'
			};
		
			fetch("https://cobtras.com/api/v1/setsigntranslation/" + event.target.parentNode.parentNode.id + "/" + spokenlanguageid, requestOptions)
				.then(response => response.text())
				.then((result) => {
					
					if(result === "+ OK"){
						toast.update(toastid, { render: "Successful updated translation", type: "success", isLoading: false, autoClose: 5000 });
					} else {
						toast.update(toastid, { render: "Error updating translation ", type: "error", isLoading: false, autoClose: 5000 });
					}
					
				})
				.catch((err) => { 
					toast.update(toastid, { render: "Error: " + err, type: "error", isLoading: false, autoClose: 5000 });
				})
			
		}		
		
	}
	
	return (
		<div class="clearfix">
			<div class="detail">
				<h1>Translation</h1>
				{loading && <p>Loading data...</p>}
      			{error && (
			        <p>{`There is a problem fetching the post data - ${error}`}</p>
      			)}
      			{data && 
      				<>
      					<h2>{languageName} - {lectionName} <FontAwesomeIcon icon="fa-solid fa-arrow-right" /> <SpokenLanguageShower value={spokenlanguageid} list={languages} /> </h2>
      					<p><Link to={ `/translations/${signlanguageid}/${spokenlanguageid}` }>Back</Link></p>
						<table className="listul">
							{data.map(({ id, name, translation }) => (
								<tr key={id} id={id} style={{borderBottom: "thick solid rgb(70, 70, 70)"}}>
									{/*TODO*/}
									<td><Sign name={name} /></td>
									<td><input onBlur={e => enterTranslation(e) } defaultValue={translation} /></td>
								</tr>
							))}
						</table>
      				</>
      			}
				<ToastContainer />
			</div>
		</div>
	);
	
}