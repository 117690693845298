import React from 'react';

class Error404 extends React.Component{
	
	constructor(props){
		super(props);
		this.state = {render: true};
	}
	
	componentDidMount(){
    	document.title = "Cobtras - 404"
  	}
	
	render() {
		return (
			<div class="clearfix">
				<div class="detail">
					<h1>404</h1>
					<p>
						We haved searched 404 times at this place, but we never found anything.
					</p>
				</div>
			</div>
		);
  }
  
}

export default Error404;