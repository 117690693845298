import React, { useState } from 'react';
import './Registration.css';
import { ToastContainer, toast } from 'react-toastify';

const Registration = () => {
	
	const [dataSended, setDataSended] = useState(false);
	const [repeatedPassword, setRepeatedPassword] = useState('');
	
	const [registration, setRegistration] = useState({
		email: "",
		name: "",
		password: ""
	});
	
	function checkPasswordRequirements(){
		
		let pw = registration.password;
		const regexSpecialCharacter = /[^a-zA-Z0-9]/;
		const regexNumber = /\d/;				
		
		if(repeatedPassword !== pw){
			
			toast.error('Passwörter stimmen nicht überein', { autoClose: 5000 });
			
			return false;
			
		}
			
		if(pw.length < 8){
			
			toast.error('Das Passwort muss mindestens 8 Zeichen haben', { autoClose: 5000 });
				
			return false;
			
		}			
				
		if(!pw.split('').some(c => c.charCodeAt(0) >= 65 && c.charCodeAt(0) <= 90)){
			
			toast.error('Das Passwort muss mindestens 1 Großbuchstaben enthalten', { autoClose: 5000 });
					
			return false;
			
		}
					
		if(!pw.split('').some(c => c.charCodeAt(0) >= 97 && c.charCodeAt(0) <= 122)){
			
			toast.error('Das Passwort muss mindestens 1 Kleinbuchstaben enthalten', { autoClose: 5000 });
					
			return false;
			
		}
		
		if(!regexNumber.test(pw)){
					
			toast.error('Das Passwort muss mindestens 1 Zahl enthalten', { autoClose: 5000 });
					
			return false;
							
		}
							
		if(regexSpecialCharacter.exec(pw) == null){
								
			toast.error('Das Passwort muss mindestens 1 Sonderzeichen enthalten', { autoClose: 5000 });
					
			return false;
								
		}
		
		return true;
		
	}
	
	function checkMailRequirements(){
		
		if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(registration.email)) {/* eslint-disable-line */
			return true;
		} else {
			
			toast.error('Sie müssen eine gülitge Mailadresse eingeben', { autoClose: 5000 });
			
			return false;
			
		}
		
	}
	
	function checkNameRequirements(){
		
		var regName = /^[a-zA-Z]+ [a-zA-Z]+$/;
		
		if(regName.test(registration.name)){
			return true;
		} else {
			
			toast.error('Sie müssen eine gülitgen Namen ([Vorname] [Nachname]) eingeben', { autoClose: 5000 });
			
			return false;
			
		}
		
	}
	
	const submit = e => {
		
		e.preventDefault();
		
		if(true && checkPasswordRequirements() && checkMailRequirements() && checkNameRequirements()){
			
			const toastid = toast.loading('Registrierung ...');
			
			var headers = new Headers();
			headers.append("Content-Type", "application/json");
			
			var requestOptions = {
  				method: 'POST',
  				headers: headers,
  				body: JSON.stringify(registration),
  				redirect: 'follow'
			};

			fetch("https://cobtras.com/api/v1/newregistration", requestOptions)
  				.then(response => response.text())
  				.then(result => handleregistrationresult(toastid, result))
  				.catch(error => handleerrorresult(toastid, error));
			
		}
		
	};
	
	function handleregistrationresult(toastid, result){
		
		if(result === "+ OK"){
			
			toast.update(toastid, { render: "Registrierung erfolgreich", type: "success", isLoading: false, autoClose: 5000 });
			
			setDataSended(true);
			
		} else {
			toast.update(toastid, { render: "Registrierung fehlgeschlagen", type: "error", isLoading: false, autoClose: 5000 });
		}
		
	}
	
	function handleerrorresult(toastid, error){
  		toast.update(toastid, { render: "Error: " + error, type: "error", isLoading: false, autoClose: 5000 });
	}
	
	return(
		<div class="clearfix">
			<div class="detail">
				<h1>Registrierung</h1>
				{!dataSended &&
					<form onSubmit={submit} className={"form"}>
						<input type={'email'} id="mailadress" placeholder='Mailadresse' value={registration.email} onChange={e => setRegistration({ ...registration, email: e.target.value })} /><br />
						<input id="name" placeholder='Name' value={registration.name}  onChange={e => setRegistration({ ...registration, name: e.target.value })} /><br />
						<input id="password" type={'password'} placeholder="Passwort" onChange={e => setRegistration({ ...registration, password: e.target.value })} /><br />
						<input id="passwordrepeat" type={'password'} placeholder="Passwort (wiederholen)" onChange={e => setRepeatedPassword(e.target.value)} /><br /><br />
						<input type="submit" value="Registrieren" className='submit' />
					</form>
				}
				{dataSended &&
					<p>
						Bitte best&auml;igen Sie Ihre Registrierung mit der Ihnen gesendeten Mail.
					</p>
				}
				<ToastContainer />
			</div>
		</div>
	);
	
}

export default Registration;
