import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export function Footer (){
	
	const [ year, setYear ] = useState(2024);
	const { t } = useTranslation();
	
	useEffect(() => {
		setYear(new Date().getFullYear())
	}, [])
	
	return (
		<div id="footer">
			<div id="mainDiv"></div>
			<div className="clearfix">
				<div className="section">
					<h4>&nbsp;</h4>
				</div>
				<div className="section contact">
					<h4><Link to="/impressum/">{t("footer1")}</Link></h4>
				</div>
				<div className="section">
					<h4><Link to="/datenschutz/">{t("footer2")}</Link></h4>
				</div>
			</div>
			<div id="footnote">
				<div className="clearfix">
					<div className="connect"></div>
					<p>
						&copy; Copyright {year} Cobtras
					</p>
				</div>
			</div>
       	</div>
	);
  
}

export default Footer;