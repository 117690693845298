import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	loaded: false,
	content: {}
};

export const datasettypesSlice = createSlice({
	
	name: 'datasettypes',
	initialState,
	reducers: {
		setDatasetTypes: (state, content) => {
			state.loaded = true;
			state.content = content.payload;
		}
	},	
});

export const {setDatasetTypes} = datasettypesSlice.actions;

export default datasettypesSlice.reducer;