import React from 'react'
import { useSelector } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export function AiTrainingResults (props) {
	
	const username = useSelector(state => state.usermangement.username);
	const password = useSelector(state => state.usermangement.password);
	const csvForm = React.useRef();
	const pdfForm = React.useRef();
	
	function downloadCSV(){
		csvForm.current.dispatchEvent(
        	new Event("submit", { cancelable: true, bubbles: true })
    	);
	}
	
	function downloadPDF(){
		pdfForm.current.dispatchEvent(
        	new Event("submit", { cancelable: true, bubbles: true })
    	);
	}
	
	return (
		<>
			{props.state === 1 &&
				<span>No results avaiable, training was not started</span>
        	}
			{props.state === 2 &&
				<span>No results avaiable, training is in progress</span>
        	}
			{props.state === 3 &&
				<p>
					<form ref={csvForm} action={"https://cobtras.com/aitrainingsresults/result_" + props.id + ".csv"} target='_blank' method='POST'>
      					<input name="mailaddress" value={username} type='hidden' />
      					<input name="password" value={password} type='hidden' />
      				</form>
					
					<form ref={pdfForm} action={"https://cobtras.com/aitrainingsresults/training_" + props.id + ".pdf"} target='_blank' method='POST'>
      					<input name="mailaddress" value={username} type='hidden' />
      					<input name="password" value={password} type='hidden' />
      				</form>
					
					<span onClick={downloadCSV}>
						<FontAwesomeIcon icon="fa-solid fa-table" title='csv' />
      				</span>&nbsp;<span onClick={downloadPDF}>
						<FontAwesomeIcon icon="fa-solid fa-chart-line" title='pdf' />
      				</span>
				</p>
        	}
			{props.state === 4 &&
				<span>No results avaiable, training failed</span>
			}
		</>
	);
	
}