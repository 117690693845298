import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	loaded: false,
	content: {}
};

export const aistatesSlice = createSlice({
	
	name: 'aistates',
	initialState,
	reducers: {
		setAiStates: (state, content) => {
			state.loaded = true;
			state.content = content.payload;
		}
	},	
});

export const {setAiStates} = aistatesSlice.actions;

export default aistatesSlice.reducer;
