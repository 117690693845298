import React, { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import vision from '../../../img/vision.png';

export function UnsereVision (){
	
	const { t } = useTranslation();
	
	useEffect(() => {
    	document.title = t("visiontitle")
  	}, [t])
	
	return (
		<div class="clearfix">
			<div class="detail">
				<div id='headline'>
					<h1 className='headlinetext'>{t("vision1")}</h1>
					<img src={vision} className='headlinepic' alt="Weg zum Ziel" />
				</div>
				<p>{t("vision2")}</p>
           		<p>{t("vision3")}</p>
           		<p>{t("vision4")}</p>
           		<p>{t("vision5")}</p>
			</div>
		</div>
	);
  
}

export default UnsereVision;