import React, { useState, useEffect, useCallback, useMemo} from "react";
import { ToastContainer, toast } from 'react-toastify';
import { /*useDispatch, */useSelector } from 'react-redux';
import Select from 'react-select';

import './TranslatorAdministration.css'

import { SignLanguageShower } from '../../../features/viewfeatures/SignLanguageShower';
import { SpokenLanguageShower } from '../../../features/viewfeatures/SpokenLanguageShower';

export function TranslatorAdministration () {
	
	const [ loading, setLoading ] = useState(true);
	const [ error, setError ] = useState(null);
	const [ translator, setTranslator ]	= useState([]);
	const [ selectedTranslator, setSelectedTranslator ] = useState("");
	const [ signLanguages, setSignLanguages] = useState([]);
	const [ signLanguagesSelect, setSignLanguagesSelect] = useState([]);
	const [ languages, setLanguages ] = useState([]);
	const [ languagesSelect, setLanguagesSelect ] = useState([]);
	const [ translatorDataLoading, setTranslatorDataLoading ] = useState(true);
	const [ translatorLanguages, setTranslatorLanguages ] = useState([]);
	const [ selectedSignLanguage, setSelectedSignLanguage ] = useState("");
	const [ selectedSpokenLanguage, setSelectedSpokenLanguage ] = useState("");
	
	const username = useSelector(state => state.usermangement.username);
	const password = useSelector(state => state.usermangement.password);
	
	const getMyHeaders = useCallback(() => {
		
		let returnValue = new Headers();
		returnValue.append("Content-Type", "application/json");
		
	}, []);
	
	const getRaw = useCallback(() => {
		
		return JSON.stringify({
			"mailaddress": username,
			"password": password
		});
		 
	}, [username, password]);
	
	var myHeaders = useMemo(() => getMyHeaders(), [getMyHeaders]); 
	var raw = useMemo(() => getRaw(), [getRaw]);
	
	useEffect(() => {
		
		document.title = 'Cobtras - Translator administration';
		
		const toastid = toast.loading('Loading data ...');

		var requestOptions = {
  			method: 'POST',
  			headers: myHeaders,
  			body: raw,
  			redirect: 'follow'
		};
		
		fetch("https://cobtras.com/api/v1/translator", requestOptions)
  			.then(response => response.text())
  			.then((result) => {
				
				let resultObj = JSON.parse(result);
				let translator = [];
				
				for(let i = 0; i < resultObj.length; i++){
					
					let translatorSelectObj = {
						value: resultObj[i].mailAddress,
						label: resultObj[i].mailAddress + " - " + resultObj[i].firstName + " " + resultObj[i].lastName
					}
					
					translator.push(translatorSelectObj);
					
				}
				
				setTranslator(translator);
				
				fetch("https://cobtras.com/api/v1/signlanguages", requestOptions)
  					.then(response => response.text())
  					.then((result) => {
				
					let resultObj = JSON.parse(result);
					let signLanguages = [];
					
					for(let i = 0; i < resultObj.length; i++){
						
						let signLanguageSelectObj = {
							value: resultObj[i].id,
							label: resultObj[i].name
						}
						
						signLanguages.push(signLanguageSelectObj);
					
					}
					
					setSignLanguagesSelect(signLanguages);
					setSignLanguages(resultObj);
					
					fetch("https://cobtras.com/api/v1/spokenlanguages", requestOptions)
  						.then(response => response.text())
  						.then((result) => {
				
							setLanguages(JSON.parse(result));
				
							setLoading(false);
							setError(null);
				
							toast.update(toastid, { render: "Successful loaded data", type: "success", isLoading: false, autoClose: 5000 });
							
						})
						.catch((err) => { 
				
							setLanguages(null);
							setError(err.message);
							setLoading(false);
				
							toast.update(toastid, { render: "Error: " + err, type: "error", isLoading: false, autoClose: 5000 });
				
						});
					
				})
				.catch((err) => { 
				
					setError(err.message);
					setSignLanguages(null);
					setLoading(false);
				
					toast.update(toastid, { render: "Error: " + err, type: "error", isLoading: false, autoClose: 5000 });
				
				});
					  
			})
			.catch((err) => { 
				
				setTranslator(null);
				setError(err.message);
				setLoading(false);
				
				toast.update(toastid, { render: "Error: " + err, type: "error", isLoading: false, autoClose: 5000 });
				
			});
		
	}, [myHeaders, raw, setError, setLoading, setTranslator, setSignLanguages, setSignLanguagesSelect]);
	
	function changeTranslator(translator) {
		
		setSelectedTranslator(translator);

		const toastid = toast.loading('Loading data ...');
		
		loadTranslatorData(toastid, translator);
		
	}
	
	function loadTranslatorData(toastid, translator) {

		var requestOptions = {
  			method: 'POST',
  			headers: myHeaders,
  			body: raw,
  			redirect: 'follow'
		};
		
		fetch("https://cobtras.com/api/v1/translator/" + translator, requestOptions)
  			.then(response => response.text())
  			.then((result) => {
				
				let resultObj = JSON.parse(result);
				setTranslatorLanguages(resultObj);
				setTranslatorDataLoading(false);
				
				toast.update(toastid, { render: "Successful loaded translator data", type: "success", isLoading: false, autoClose: 5000 });
				
			})
			.catch((err) => { 
				
				setError(err.message);
				setTranslatorLanguages(null);
				
				toast.update(toastid, { render: "Error: " + err, type: "error", isLoading: false, autoClose: 5000 });
				
			});
		
		toast.update(toastid, { render: "Error: ", type: "error", isLoading: false, autoClose: 5000 });
		
	}
	
	function backToTranslatorSelection() {
		
		setSelectedTranslator("");
		setTranslatorDataLoading(true);
		setSelectedSignLanguage("");
		setSelectedSpokenLanguage("");
		
	}
	
	function changeNewSignLanguage(selectedSignLanguage){
		
		setSelectedSignLanguage(selectedSignLanguage);
		
		let selectObject = [];
		let commonSpokenLanguage = "";
		
		for(let i = 0; i < signLanguages.length; i++){
			
			if(signLanguages[i].id === selectedSignLanguage) {
				commonSpokenLanguage = signLanguages[i].commonSpokenLanguage;
			}
			
		}
		
		for( let i = 0; i < languages.length; i++){
			
			if(languages[i][0] !== commonSpokenLanguage ){
				
				let selectObjectEntry = {
					value: languages[i][0],
					label: languages[i][1] + "/" + languages[i][2] + " (" + languages[i][0] + ")"
				}
			
				selectObject.push(selectObjectEntry);
				
			}
			
		}
		
		setLanguagesSelect(selectObject);
		
	}
	
	function changeNewSpokenLanguage(selectedSpokenLanguage){
		setSelectedSpokenLanguage(selectedSpokenLanguage);
	}
	
	function addNewTranslatorLanguage(){
		
		const toastid = toast.loading('Adding translator language ...');

		var requestOptions = {
  			method: 'POST',
  			headers: myHeaders,
  			body: raw,
  			redirect: 'follow'
		};
		
		fetch("https://cobtras.com/api/v1/addtranslatorlanguage/" + selectedTranslator + "/" + selectedSignLanguage + "/" + selectedSpokenLanguage, requestOptions)
  			.then(response => response.text())
  			.then((result) => {
				
				if(result === "+ OK"){
					
					loadTranslatorData(toastid, selectedTranslator);
					
					setSelectedSignLanguage("");
					setSelectedSpokenLanguage("");
					setLanguagesSelect([]);
					
				} else {
					toast.update(toastid, { render: "Failed saving new translator language", type: "error", isLoading: false, autoClose: 5000 });
				}
				
			})
			.catch((err) => { 
				
				setError(err.message);
				
				toast.update(toastid, { render: "Error: " + err, type: "error", isLoading: false, autoClose: 5000 });
				
			});
		
	}
	
	function deleteTranslatorLanguage(languageCombination){
		
		const toastid = toast.loading('Deleting translator language ...');

		var requestOptions = {
  			method: 'POST',
  			headers: myHeaders,
  			body: raw,
  			redirect: 'follow'
		};
		
		fetch("https://cobtras.com/api/v1/removetranslatorlanguage/" + selectedTranslator + "/" + languageCombination[0] + "/" + languageCombination[1], requestOptions)
  			.then(response => response.text())
  			.then((result) => {
				
				if(result === "+ OK"){
					loadTranslatorData(toastid, selectedTranslator);
				} else {
					toast.update(toastid, { render: "Failed removing translator language", type: "error", isLoading: false, autoClose: 5000 });
				}
				
			})
			.catch((err) => { 
				
				setError(err.message);
				
				toast.update(toastid, { render: "Error: " + err, type: "error", isLoading: false, autoClose: 5000 });
				
			});
		
	}
	
	return (
		<div class="clearfix">
			<div class="detail">
				<h1>Translator Administration</h1>
				{loading && <p>Loading data...</p>}
      			{error && (
			        <p>{`There is a problem fetching the post data - ${error}`}</p>
      			)}
      			{!loading &&
      				<>
						{selectedTranslator === "" &&
							<>
								<h2>Select translator for editing rights</h2>
    	  						<Select options={translator} className="select" onChange={e => changeTranslator(e.value)} />
    	  					</>
    	  				}
						{selectedTranslator !== "" &&
      						<>
      							{translatorDataLoading && <p>Loading data</p>}
								{error && (
			        				<p>{`There is a problem fetching the post data - ${error}`}</p>
      							)}
      							{!translatorDataLoading &&
									<>
										<p><button onClick={backToTranslatorSelection}>Back</button></p>
										<h2>{selectedTranslator}</h2>
										{translatorLanguages.length === 0 &&
											<p>Currently no languages for this translator</p>
										}
										{translatorLanguages.length !== 0 &&
											<ul className="ulwithmargin">
												{translatorLanguages.map((object) =>
													<li>
														<SignLanguageShower value={object[0]} list={signLanguages} /> - <SpokenLanguageShower value={object[1]} list={languages} /> 
														<span className="spanBtn" onClick={ e => deleteTranslatorLanguage(object) }>Delete</span>
													</li>
												)}
											</ul>
										}
										<h2>Add new language for translator:</h2>
										<p>
											<Select options={signLanguagesSelect} onChange={e => changeNewSignLanguage(e.value)} />
											<Select options={languagesSelect} isDisabled={selectedSignLanguage === ""} onChange={e => changeNewSpokenLanguage(e.value)} />
											{selectedSpokenLanguage !== "" &&
												<span className="spanBtn" onClick={addNewTranslatorLanguage} >Add</span>
											}
										</p>
									</>
								}
							</>
						}
      				</>
      			}
				<ToastContainer />
			</div>
		</div>
	)
	
}
