import React, {useEffect} from 'react';
import { useTranslation } from "react-i18next";
import meeting from '../../../img/meeting.jpg';

export function History() {

	const { t } = useTranslation();
	
	useEffect(() => {
    	document.title = t("historytitle")
  	}, [t])
	
	return (
		<div class="clearfix">
			<div class="detail">
				<div id='headline'>
					<h1 className='headlinetext'>{t("history1")}</h1>
					<img src={meeting} className='headlinepic' alt='Meeting' />
				</div>
				<p>{t("history2")}</p>
				<h2>{t("history3")}</h2>
				<p>{t("history4")}</p>
				<h2>{t("history5")}</h2>
				<p>{t("history6")}</p>
				<h2>{t("history7")}</h2>
				<p>{t("history8")}</p>
				<h2>{t("history9")}</h2>
				<p>{t("history10")}</p>
				<h2>{t("history11")}</h2>
				<p>{t("history12")}</p>
				<p>{t("history13")}</p>
			</div>
		</div>
	);
  
}

export default History;