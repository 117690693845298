import React from 'react'

export function SpeakerType (props) {
	
	return (
		<>
			{props.type === 1 &&
				<span>Training</span>
        	}
			{props.type === 2 &&
				<span>Test & validation</span>
        	}
		</>
	);
	
}