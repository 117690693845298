import React, { useState, useEffect, useCallback, useMemo } from "react";
import { ToastContainer, toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select'

import { setPassword } from "../../../features/usermanagement/usermanagementSlice";

import './UserSettings.css';


export function UserSettings () {
	
	const dispatch = useDispatch();
	
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [settings, setSettings] = useState({
		firstName: "",
		secondName: "",
		birthday: "",
		addressCountry: "",
		addressState: "",
		addressPostalCode: "",
		addressCity: "",
		addressStreet: "",
		addressNumber: "",
		telephonnumber: "",
		/*eMail: "",*/
		language: "",
		gender: ""
	});
	const [passwordChange, setPasswordChange] = useState({
		oldPassword: "",
		newPassword: "",
		newPasswordRepeat: ""
	});
	const [mailAddressChange, setMailAddressChange] = useState("");
	const genderOptions = [
		{ value: 'f', label: 'female'},
		{ value: 'm', label: 'male'}
	];
	const [ changedMailAddress, setChangedMailAddress ] = useState(false);
   		
	const username = useSelector(state => state.usermangement.username);
	const password = useSelector(state => state.usermangement.password);
	
	const getMyHeaders = useCallback(() => {
		
		let returnValue = new Headers();
		returnValue.append("Content-Type", "application/json");
		
	}, []);
	
	const getRaw = useCallback(() => {
		
		return JSON.stringify({
			"mailaddress": username,
			"password": password
		});
		 
	}, [username, password]);
	
	var myHeaders = useMemo(() => getMyHeaders(), [getMyHeaders]); 
	var raw = useMemo(() => getRaw(), [getRaw]);
	
	function checkPasswordRequirements(){
		
		let pw = passwordChange.newPassword;
		const regexSpecialCharacter = /[^a-zA-Z0-9]/;
		const regexNumber = /\d/;				
		
		if(passwordChange.newPasswordRepeat !== pw){
			
			toast.error('Passwörter stimmen nicht überein', { autoClose: 5000 });
			
			return false;
			
		}
			
		if(pw.length < 8){
			
			toast.error('Das Passwort muss mindestens 8 Zeichen haben', { autoClose: 5000 });
				
			return false;
			
		}			
				
		if(!pw.split('').some(c => c.charCodeAt(0) >= 65 && c.charCodeAt(0) <= 90)){
			
			toast.error('Das Passwort muss mindestens 1 Großbuchstaben enthalten', { autoClose: 5000 });
					
			return false;
			
		}
					
		if(!pw.split('').some(c => c.charCodeAt(0) >= 97 && c.charCodeAt(0) <= 122)){
			
			toast.error('Das Passwort muss mindestens 1 Kleinbuchstaben enthalten', { autoClose: 5000 });
					
			return false;
			
		}
		
		if(!regexNumber.test(pw)){
					
			toast.error('Das Passwort muss mindestens 1 Zahl enthalten', { autoClose: 5000 });
					
			return false;
							
		}
							
		if(regexSpecialCharacter.exec(pw) == null){
								
			toast.error('Das Passwort muss mindestens 1 Sonderzeichen enthalten', { autoClose: 5000 });
					
			return false;
								
		}
		
		return true;
		
	}
	
	useEffect(() => {
		
		document.title = 'Cobtras - Settings';
		
		const toastid = toast.loading('Loading data ...');

		var requestOptions = {
  			method: 'POST',
  			headers: myHeaders,
  			body: raw,
  			redirect: 'follow'
		};
		
		fetch("https://cobtras.com/api/v1/getuserdata", requestOptions)
  			.then(response => response.text())
  			.then((result) => {
				
				const parser = new DOMParser();
    			const xml = parser.parseFromString(result, 'text/xml');
    			const member = xml.querySelector("Member");
				
				setSettings({
					firstName: member.querySelector("firstName").innerHTML,
					secondName: member.querySelector("secondName").innerHTML,
					birthday: member.querySelector("birthday").innerHTML,
					addressCountry: member.querySelector("addressCountry").innerHTML,
					addressState: member.querySelector("addressState").innerHTML,
					addressPostalCode: member.querySelector("addressPostalCode").innerHTML,
					addressCity: member.querySelector("addressCity").innerHTML,
					addressStreet: member.querySelector("addressStreet").innerHTML,
					addressNumber: member.querySelector("addressNumber").innerHTML,
					telephonnumber: member.querySelector("telephonnumber").innerHTML,
					language: member.querySelector("language").innerHTML,
					gender: member.querySelector("gender").innerHTML});
				
				setLoading(false);
				setError(null);
				
				toast.update(toastid, { render: "Successful loaded data", type: "success", isLoading: false, autoClose: 5000 });
					  
			})
			.catch((err) => { 
				
				setError(err.message);
				setSettings(null);
				
				toast.update(toastid, { render: "Error: " + err, type: "error", isLoading: false, autoClose: 5000 });
				
			});
		
	}, [myHeaders, raw, setSettings, setError, setLoading]);
	
	function handleUpdateUser(event) {
		
		event.preventDefault();
		
		const toastid = toast.loading('Updating settings ...');
		
		let json = {
			mailaddress: username,
			password: password,
			firstName: settings.firstName,
			secondName: settings.secondName,
			birthday: settings.birthday,
			addressCountry: settings.addressCountry,
			addressState: settings.addressState,
			addressPostalCode: settings.addressPostalCode,
			addressCity: settings.addressCity,
			addressStreet: settings.addressStreet,
			addressNumber: settings.addressNumber,
			telephonnumber: settings.telephonnumber,
			eMail: username, 
			language: settings.language,
			gender: settings.gender
		}

		var requestOptions = {
  			method: 'POST',
  			headers: myHeaders,
  			body: JSON.stringify(json),
  			redirect: 'follow'
		};
		
		fetch("https://cobtras.com/api/v1/setuserdata", requestOptions)
  			.then(response => response.text())
  			.then((result) => {
				  
				  if(result === "+ OK"){
					  toast.update(toastid, { render: "Successful updated settings", type: "success", isLoading: false, autoClose: 5000 });
				  } else {
					  toast.update(toastid, { render: "Error: " + result, type: "error", isLoading: false, autoClose: 5000 });
				  }

			})
			.catch((err) => { 
				toast.update(toastid, { render: "Error: " + err, type: "error", isLoading: false, autoClose: 5000 });
			});
		
	}
	
	function handleChangePassword(event) {
		
		event.preventDefault();
		
		if(password !== passwordChange.oldPassword){
			
			toast.error('The old password is not correct', { autoClose: 5000 });
			
			return;
			
		}
		
		if(checkPasswordRequirements()){

			const toastid = toast.loading('Updating settings ...');
		
			let json = {
				mailaddress: username,
				password: password,
				newpassword: passwordChange.newPassword
			}

			var requestOptions = {
	  			method: 'POST',
  				headers: myHeaders,
  				body: JSON.stringify(json),
  				redirect: 'follow'
			};
		
			fetch("https://cobtras.com/api/v1/updatepassword", requestOptions)
  				.then(response => response.text())
  				.then((result) => {
					  
					if(result === "+ OK"){
						
						dispatch(setPassword(passwordChange.newPassword));
						
						setPasswordChange({
							oldPassword: "",
							newPassword: "",
							newPasswordRepeat: ""
						});
						
						toast.update(toastid, { render: "Successful updated password", type: "success", isLoading: false, autoClose: 5000 });
						
					} else {
						toast.update(toastid, { render: "Error: " + result, type: "error", isLoading: false, autoClose: 5000 });
					}

				})
				.catch((err) => { 
					toast.update(toastid, { render: "Error: " + err, type: "error", isLoading: false, autoClose: 5000 });
				});
			
		}
		
	}
	
	function handleChangeMail(event){
		
		event.preventDefault();
		
		const toastid = toast.loading('Updating mailaddress ...');
		
		let json = {
			mailaddress: username,
			password: password,
			newmailaddress: mailAddressChange
		}

		var requestOptions = {
	  		method: 'POST',
  			headers: myHeaders,
  			body: JSON.stringify(json),
  			redirect: 'follow'
		};
		
		fetch("https://cobtras.com/api/v1/updatemailaddress", requestOptions)
  			.then(response => response.text())
  			.then((result) => {
					  
				if(result === "+ OK"){
						
					setChangedMailAddress(true);
					setMailAddressChange("");
						
					toast.update(toastid, { render: "Successful updated mailaddress", type: "success", isLoading: false, autoClose: 5000 });
						
				} else {
					toast.update(toastid, { render: "Error: " + result, type: "error", isLoading: false, autoClose: 5000 });
				}

			})
			.catch((err) => { 
				toast.update(toastid, { render: "Error: " + err, type: "error", isLoading: false, autoClose: 5000 });
			});
		
	}
	
	return (
		<div class="clearfix">
			<div class="detail">
				<h1>Settings</h1>
				{loading && <p>Loading data...</p>}
      			{error && (
			        <p>{`There is a problem fetching the post data - ${error}`}</p>
      			)}
      			{!loading &&
      				<>
      					<h2>User data</h2>
      					<form  onSubmit={handleUpdateUser}>
      						<table className="usersettingtable">
      							<tbody>
      								<tr>
      									<td>
      										Firstname:
	      								</td>
	      								<td>
	      									<input size="50" type="text" name="firstName" required value={settings.firstName}  onChange={e => setSettings({ ...settings, firstName: e.target.value })} />
	      								</td>
    	  							</tr>
      								<tr>
      									<td>
      										Lastname:
      									</td>
	      								<td>
	      									<input size="50" type="text" name="secondName" required value={settings.secondName}  onChange={e => setSettings({ ...settings, secondName: e.target.value })} />
	      								</td>
      								</tr>
      								<tr>
      									<td>
      										Birthday:
	      								</td>
	      								<td>
	      									<input size="50" type="date" name="birthday" required value={settings.birthday}  onChange={e => setSettings({ ...settings, birthday: e.target.value })} />
	      								</td>
    	  							</tr>
      								<tr>
      									<td>
      										Address Street:
      									</td>
	      								<td>
	      									<input size="50" type="text" name="addressStreet" required value={settings.addressStreet}  onChange={e => setSettings({ ...settings, addressStreet: e.target.value })} />
	      								</td>
      								</tr>
      								<tr>
      									<td>
      										Address Number:
      									</td>
	      								<td>
	      									<input size="50" type="text" name="addressNumber" required value={settings.addressNumber}  onChange={e => setSettings({ ...settings, addressNumber: e.target.value })} />
	      								</td>
	      							</tr>
    	  							<tr>
      									<td>
      										Address City:
      									</td>
	      								<td>
	      									<input size="50" type="text" name="addressCity" required value={settings.addressCity}  onChange={e => setSettings({ ...settings, addressCity: e.target.value })} />
	      								</td>
      								</tr>
      								<tr>
      									<td>
      										Address postal code:
      									</td>
	      								<td>
	      									<input size="50" type="text" name="addressPostalCode" required value={settings.addressPostalCode}  onChange={e => setSettings({ ...settings, addressPostalCode: e.target.value })} />
	      								</td>
	      							</tr>
    	  							<tr>
      									<td>
      										Address state:
      									</td>
	      								<td>
	      									<input size="50" type="text" name="addressState" required value={settings.addressState}  onChange={e => setSettings({ ...settings, addressState: e.target.value })} />
	      								</td>
      								</tr>
      								<tr>
      									<td>
      										Address country:
      									</td>
	      								<td>
	      									<input size="50" type="text" name="addressCountry" required value={settings.addressCountry}  onChange={e => setSettings({ ...settings, addressCountry: e.target.value })} />
	      								</td>
	      							</tr>
    	  							<tr>
      									<td>
      										Telefon number:
      									</td>
	      								<td>
	      									<input size="50" type="text" name="telphonnumber" required value={settings.telephonnumber}  onChange={e => setSettings({ ...settings, telephonnumber: e.target.value })} />
	      								</td>
      								</tr>
      								<tr>
      									<td>
      										Gender:
      									</td>
	      								<td>
	      									<Select size="50" type="text" name="gender" required options={genderOptions} defaultValue={settings.gender}  onChange={e => setSettings({ ...settings, gender: e.value })}></Select>
	      								</td>
	      							</tr>
	      							<tr>
	      								<td></td>
	      								<td><input type="submit" value="Save" className="userSettingsBtn" /></td>
	      							</tr>
    	  						</tbody>
      						</table>
      					</form>
      					<h2>Password</h2>
      					<form onSubmit={handleChangePassword}>
      						<table className="usersettingtable">
      							<tbody>
      								<tr>
      									<td>
      										Old password:
      									</td>
      									<td>
      										<input size="50" type="password" required value={passwordChange.oldPassword}  onChange={e => setPasswordChange({ ...passwordChange, oldPassword: e.target.value })} />
      									</td>
      								</tr>
      								<tr>
      									<td>
      										New password:
      									</td>
      									<td>
      										<input size="50" type="password" required value={passwordChange.newPassword}  onChange={e => setPasswordChange({ ...passwordChange, newPassword: e.target.value })} />
      									</td>
      								</tr>
      								<tr>
      									<td>
      										Repeat new password:
      									</td>
      									<td>
      										<input size="50" type="password" required value={passwordChange.newPasswordRepeat}  onChange={e => setPasswordChange({ ...passwordChange, newPasswordRepeat: e.target.value })} />
      									</td>
      								</tr>
      								<tr>
      									<td></td>
      									<td><input type="submit" value="Change password" className="userSettingsBtn" /></td>
      								</tr>
      							</tbody>
      						</table>
      					</form>
      					<h2>Mailaddress</h2>
      					<form onSubmit={handleChangeMail}>
      						<p>
      							Please insert your new mailaddress: 
      							<input type="email" size="30"required value={mailAddressChange} onChange={e => setMailAddressChange(e.target.value)}  /><br />
      							<input type="submit" value="Update mail address" className="userSettingsBtn" />
      						</p>
      					</form>
      					{changedMailAddress && <p>Please check your mails for confirming your new mailaddress...</p>}
      				</>
      			}
      			<ToastContainer />
			</div>
		</div>
	);
	
}
