import React from 'react'

export function SpokenLanguageShower (props) {
	
	let writtenText = "";
	
	for(let i = 0; i < props.list.length; i++){
		
		if(props.list[i][0] === props.value){
			writtenText = props.list[i][1] + "/" + props.list[i][2] + " (" + props.list[i][0] + ")";
		}
		
	}
	
	
	return (
		<>
			{writtenText}
		</>
	);
	
}